import {FC} from 'react'
import { Loader } from '../../index'
import { Btn} from '../../../ui'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import {
  getIsLoading,
} from '../../../redux/slices/access'
import {IConfirmExitModal} from './interfaces.d'
import './style.scss';
import {clearUnSaveData} from "../../../redux/slices/warnings";
import {trans} from "../../../_locales";
import {currentUserLang} from "../../../redux/slices/user";

const ConfirmExitModal: FC<IConfirmExitModal> = ({
  onClose,
  confirm,
  confirmText='Leave',
  resetText='Cancel',
  message='You have unsaved changes.',
  cancel
}) => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(getIsLoading);
  const language = useAppSelector(currentUserLang);

  const onReset = ()=> {
    onClose('CONFIRM_EXIT')
    cancel()
  }
  const onSubmit = ()=> {
    dispatch(clearUnSaveData())
    onClose('CONFIRM_EXIT')
    confirm()
  }
  return (
    <>
      <p className={'confirm-exit__title'}>{trans(message, language)}<br/>
        {trans('Leave the page?', language)}</p>
      <div className="confirm-exit-action">
        <Btn
          type='reset'
          text={trans(resetText, language)}
          size='lg'
          widthAuto={false}
          onClick={onReset}
        />
        <Btn
          type='submit'
          text={trans(confirmText, language)}
          color='blue'
          widthAuto={false}
          size='lg'
          disabled={false}
          onClick={onSubmit}
        />
      </div>
      {isLoading && <Loader />}
    </>
  );
};

export default ConfirmExitModal;
