import { JSXElementConstructor, ReactElement } from 'react';

export type Content = string | ReactElement<any, string | JSXElementConstructor<any>>
export type Components<T, C> = {[key in T]: C}
export type DefaultObject<T> = {[index: string]: T}
export type AnyEvent = MouseEvent | TouchEvent

export interface ISelectOption  {
  label: string
  value: string | number
}

export interface IField {
  tag: 'input' | 'select' | 'date' | 'tags' | 'select_new'
  type: string
  name: string
  placeholder: string
  label?: string
  labelWithUrls?: Array<{text:string, href?:string}>
  tooltip?: string
  tooltipSide?: 'right' | 'left'
  errorLabel: string
  isRequired: boolean
  hasAddButton?: boolean
  activeOption?: string | number
  options?: ISelectOption[]
  isOpenUp?: boolean
  isUrl?: boolean
  isSearch?: boolean
  isPasswordSwitch?: boolean
  subBtn?: {
    text: string
    href?: string
    onClick?: () => void
  }
  tip?:any
  limitNumber?:number
  limitSymbols?:number
  beta?: boolean
  dataset?: DefaultObject<any>,
  insertInOtherField?: DefaultObject<DefaultObject<string>>
  resetBtn?: boolean
  isDisabled?: boolean
}

export interface IProject {
  id: number
  owner_id: number
  name: string
  site: string
  site_counter_connect: boolean
  impression_counter_connect: boolean
  funnel_connect_connect: boolean
  status: string
  currency?: 'USD' | 'EUR' | 'RUB';
  created_at: string
  timezone?: string;
  admin_email?: string;
  receive_data_only_from_domain?: boolean;
  author_name?: string;
  author_email?: string;
  stats?: {
    impression: number
    clicks: number
    spent: number
    leads: number // pv_leads: number
    postview_cpa: number // pv_cpa: number
  }
}

export interface IIntegrations {
  categories: any
  integrations: IIntegration[]
}

export interface IIntegration {
  id: number
  title: string
  img: string
  category: string
}

export interface IProjectAttributions {
  attribution_window: number;
  attribution_model: string;
  attribution_period: string;
  attribution_report_period_days: number;
}

export interface IProjectUsers {
  id: number
  name?: string
  last_name?: string
  email: string
  role: string,
  traffic_type: string,
  traffic_channel?: string,
  traffic_name?: string
  expires: string
  last_seen: string
    verified: boolean;
}


export enum ItemStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  ARCHIVE = 'archive',
  DELETED = 'deleted'
}

export interface ICampaign {
  id: number
  created_at: string,
  project_id: number
  source_id: number
  status: ItemStatus
  product: string
  name: string
  description: string
  ads_type: 'Reach' | 'BrandFormance' | 'Lead',
  creative: string,  // ??
  attribute_impression_window: number // attribution_displays
  attribute_click_window: 10 // attribution_clicks
  start_date: string // date_start
  end_date: string // date_start
  onboarding_url: string // was landing_page
  purchase_type: 'CPM' | 'CPC' | 'CPV' | 'FIX' // was paid_type
  cost_plan: number // indicator_budget
  impression_plan: number // indicator_displays
  post_view_lead_plan: number // indicator_pv_orders
  click_plan: number // indicator_clicks
}

export interface ICampaignWithStats extends ICampaign {
  stats: {
    impression: number
    reach: number
    clicks: number
    spent: number
    leads: number
    postview_cpa: number
  }
}

export interface IPlacement {
  id: number
  project_id: number
  source_id: number
  campaign_id: number
  created_at: string
  status: ItemStatus
  name: string
  format: string
  geo: string
  targeting: string // was targeting
  creative: string
  size: string // was ad_size
  video_duration: number  // was duration
  video_orientation: string // was landscape
  stats: {
    impression: number
    reach: number
    clicks: number
    spent: number
    leads: number
    postview_cpa: number
  }
}

export interface IMediaArrayItem {
  MediaCampaign: ICampaign
  Placement: IPlacement[]
}

export interface IMedia {
  Project: IProject
  MediaCampaign: IMediaArrayItem[]
}

export interface IMediaResponse {
  data: IMedia
  message: string
  status: string
}

export interface ICampaignsResponse {
  data: ICampaignWithStats[]
  message: string
  status: string
}

export type IModelAttribution = 'fl' | 'fli' | 'ffi' | 'ftd' | 'ftdm' | 'ml' | 'mli' | 'mfi' | 'mtd' | 'flnd' | 'fw' | 'mw';
export type IWindowAttribution = 1 | 7 | 15 | 30 | 60 | 90;
