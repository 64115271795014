export const fields = {
    'interaction_dt': 'Период',
    'campaign_nm': 'Название кампании',
    'placement_nm': 'Название креатива',
    "source_nm": "Медиа источник",
    'ssp': 'SSP',
    'domain': 'Домен',
    'device_type': 'Тип девайса',
    'height': 'Высота Креатива',
    'weight': 'Ширина Креатива'

}
export const fieldsConditions = {
    // 'interaction_dt': {
    //     'contain': 'Содержит',
    //     'not_contain': 'Не содержит',
    //     'equal': 'Равно',
    //     'not_equal': 'Не равно'
    // },
    'campaign_id': {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    'placement_id': {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    "source_nm": {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    'ssp': {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    'domain': {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    'device_type': {
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    'height': {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    'weight': {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
}
export const fieldsConditionValue = {
    // 'interaction_dt': '',
    'campaign_id': '',
    'placement_id': '',
    'source_nm': '',
    'ssp': '',
    'domain': '',
    'device_type': {
        'desktop': 'Десктоп',
        'mobile': 'Телефон',
        'table': 'Планшет',
        'app': 'Приложение',
        'tv': 'ТВ',
        'other': 'Другое'
    },
    'height': '',
    'weight': '',
}

export const fieldsConditionValueTypes = {
    'campaign_id': 'string',
    'placement_id': 'string',
    'ssp': 'string',
    'domain': 'string',
    'device_type': 'object',
    'height': 'string',
    'weight': 'string',
}

export const metrics = {
    'unique_devices': 'Охват',
    'impressions': 'Показы',
    'givt': "GIVT",
    'fraud_impressions': 'SIVT',
    // 'part_of_fraud_impressions': 'Доля сомнительных показов (GIVT + SIVT)',
    'part_of_givt': "Доля показов GIVT",
    'part_of_sivt': "Доля показов SIVT",
    'clicks': 'Клики',
    'visible_impressions': 'Видимые показы',
    'part_of_visible_impressions': 'Доля видимых показов',
    'part_of_screen': 'Часть от экрана',
    'part_of_visible_screen': 'Видимая часть от экрана',
    'visible_part': 'Видимая часть',
    'creative_weight': 'Ширина креатива',
    'creative_height': 'Высота креатива',
    'video_start': 'Старт видео',
    'video_start_on_mute': 'Старт видео с отключенным звуком',
    'video_q25': '25% видео',
    'video_q50': '50% видео',
    'video_q75': '75% видео',
    'video_complete': 'Досмотр видео',
    'video_close': 'Закрыто видео',
    'video_skip': 'Пропуск видео',

    'givt_clicks': 'GIVT Клики (шт.)',
    'share_of_givt_clicks': 'GIVT Клики, (%)',
    'givt_hidden_impressions': 'GIVT: Скрытие показы, (шт)',
    'givt_hidden_impression_share_of_total_gift': 'GIVT: Скрытие показы, (%)',
    'givt_not_humans': 'GIVT: Роботное поведение, (шт)',
    'givt_not_human_share_of_total_gift': 'GIVT: Автоматизированные запросы, (%)',
    'givt_crowlers': 'GIVT: Роботы, (шт)',
    'givt_crowler_share_of_total_gift': 'GIVT: Роботы, (%)',
    'givt_data_centres': 'GIVT: Даты центры, (шт)',
    'givt_data_centre_share_of_total_gift': 'GIVT: Даты центры, (%)',
    'custom_visible': 'Пользовательский видимый показ, (шт)',
    'share_custom_visible': 'Пользовательский видимый показ, (%)',
    'avg_video_full_screen_time': 'Среднее время видео на полный экране',
    'avg_video_current_time': 'Среднее время досмотра видео',
    'avg_video_mute_second': 'Среднее время просмотра видео без звука',
    'avg_un_mute_video_second': 'Среднее время просмотра видео со звуком'
}

export const metricsConditions = {
    'unique_devices': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'impressions': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'givt': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'fraud_impressions': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'part_of_fraud_impressions': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'clicks': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'visible_impressions': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'part_of_visible_impressions': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'part_of_screen': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'part_of_visible_screen': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'visible_part': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'creative_weight': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'creative_height': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'video_start': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'video_start_on_mute': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'video_q25': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'video_q50': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'video_q75': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'video_complete': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'video_close': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'video_skip': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'givt_clicks': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'share_of_givt_clicks': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'givt_hidden_impressions': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'givt_hidden_impression_share_of_total_gift': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'givt_not_humans': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'givt_not_human_share_of_total_gift': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'givt_crowlers': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'givt_crowler_share_of_total_gift': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'givt_data_centres': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'givt_data_centre_share_of_total_gift': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'custom_visible': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'share_custom_visible': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'avg_video_full_screen_time': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'avg_video_current_time': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'avg_video_mute_second': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'avg_un_mute_video_second': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    }
}

export const metricsConditionValue = {
    'unique_devices': '',
    'impressions': '',
    'givt': '',
    'fraud_impressions': '',
    'part_of_fraud_impressions': '',
    'clicks': '',
    'visible_impressions': '',
    'part_of_visible_impressions': '',
    'part_of_screen': '',
    'part_of_visible_screen': '',
    'visible_part': '',
    'creative_weight': '',
    'creative_height': '',
    'video_start': '',
    'video_start_on_mute': '',
    'video_q25': '',
    'video_q50': '',
    'video_q75': '',
    'video_complete': '',
    'video_close': '',
    'video_skip': '',

    'givt_clicks': '',
    'share_of_givt_clicks': '',
    'givt_hidden_impressions': '',
    'givt_hidden_impression_share_of_total_gift': '',
    'givt_not_humans': '',
    'givt_not_human_share_of_total_gift': '',
    'givt_crowlers': '',
    'givt_crowler_share_of_total_gift': '',
    'givt_data_centres': '',
    'givt_data_centre_share_of_total_gift': '',
    'custom_visible': '',
    'share_custom_visible': '',
    'avg_video_full_screen_time': '',
    'avg_video_current_time': '',
    'avg_video_mute_second': '',
    'avg_un_mute_video_second': ''
}

export const metricsConditionValueTypes = {
    'unique_devices': '',
    'impressions': '',
    'givt': '',
    'fraud_impressions': '',
    'part_of_fraud_impressions': 'percent',
    'clicks': '',
    'visible_impressions': '',
    'part_of_visible_impressions': 'percent',
    'part_of_screen': '',
    'part_of_visible_screen': 'percent',
    'visible_part': '',
    'creative_weight': '',
    'creative_height': '',
    'video_start': '',
    'video_start_on_mute': '',
    'video_q25': '',
    'video_q50': '',
    'video_q75': '',
    'video_complete': '',
    'video_close': '',
    'video_skip': '',

    'givt_clicks': '',
    'share_of_givt_clicks': 'percent',
    'givt_hidden_impressions': '',
    'givt_hidden_impression_share_of_total_gift': 'percent',
    'givt_not_humans': '',
    'givt_not_human_share_of_total_gift': 'percent',
    'givt_crowlers': '',
    'givt_crowler_share_of_total_gift': 'percent',
    'givt_data_centres': '',
    'givt_data_centre_share_of_total_gift': 'percent',
    'custom_visible': '',
    'share_custom_visible': '',
    'avg_video_full_screen_time': '',
    'avg_video_current_time': '',
    'avg_video_mute_second': '',
    'avg_un_mute_video_second': ''
}

