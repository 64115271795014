import {FC, useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../../redux/hooks";

import {dashboardsFilterValues} from "../../../../redux/slices/dashboards";
import {getDashboardsFilterValues} from "../../../../actions/dashboards";

import Select from "../../../ui/Select";
import Input from "../../../../ui/Input";
import Loader from "../../../Loader";

import iconCart from "../../../../assets/icons/icon-cart.svg";
import {currentUserLang} from "../../../../redux/slices/user";
import {trans} from "../../../../_locales";

interface IFilterItem {
    id:number
    projectId:number
    onChange:any,
    onRemove:any,
    filterName:any
    filterCondition:any
    filterValue:any
    datasetName:any
    datasetNameBase:any
    datasetConditions:any
    datasetValue?:any
    isCheckbox?:boolean
    datasetValueTypes?:any
    isDelay?:boolean,
    valuePosition?: 'left' | 'right'
}

const FilterItem:FC<IFilterItem> = ({
        id,
        projectId,
        onChange,
        onRemove,
        filterName,
        filterCondition,
        filterValue,
        datasetName,
        datasetNameBase,
        datasetConditions,
        datasetValue,
        isCheckbox=true,
        datasetValueTypes,
        isDelay=false,
        valuePosition= 'right'
    }) => {
    const dispatch = useAppDispatch();
    const reduxFilterValues = useAppSelector(dashboardsFilterValues);
    const language = useAppSelector(currentUserLang);

    const [showLoader, setShowLoader] = useState(false);
    const [readyValue, setReadyValue] = useState(false);

    const [name, setName] = useState<string>(filterName);
    const onChangeName = (response: object) => {
        for (const value in response) {
            setName(value)
            const temp:any = {name: value, condition: '', value: ''};
            if(datasetValueTypes && datasetValueTypes[value]) {
                temp['type'] = datasetValueTypes[value]
            }
            onChange(temp, id);
            setConditions('');
            setValue('')
            if(datasetValue && typeof (datasetValue as any)[value] === 'object') {
                if(value === 'purchase_type' || value === 'device_type' || value === 'impression_platform' || value === 'event_target_platform') {
                    setDatasetValues((datasetValue as any)[value]);
                    setReadyValue(true);
                    setShowLoader(false);
                } else {
                    setShowLoader(true);
                    projectId && dispatch(getDashboardsFilterValues({projectId: projectId, name: value})).then((r)=> {
                        if(r.payload)  {
                            setDatasetValues(r.payload)
                            setReadyValue(true);
                        } else {
                            setReadyValue(false);
                        }
                    })
                    setShowLoader(false);
                }
            } else {
                setReadyValue(true);
                setShowLoader(false);
            }
        }
    }

    const [conditions, setConditions] = useState<string>(filterCondition);
    const onChangeConditions = (response: object) => {
        for (const value in response) {
            setConditions(value);
            setValue('');
            onChange({condition: value, value: []}, id)
        }
    }
    useEffect(()=> {
       if(typeof filterValue === 'object' && filterValue.length > 0) {
           if(name === 'purchase_type' || name === 'device_type') {
               setDatasetValues((datasetValue as any)[name]);
               setReadyValue(true);
               setShowLoader(false);
           } else {
               setShowLoader(true);
               projectId && dispatch(getDashboardsFilterValues({projectId: projectId, name: name})).then((r) => {
                   if (r.payload) {
                       setDatasetValues(r.payload)
                       setReadyValue(true);
                   } else {
                       setReadyValue(false);
                   }
               })
               setShowLoader(false);
           }
       }
       if(typeof filterValue === 'string' || typeof filterValue === 'number') setReadyValue(true);
    },[])
    const prepareValue = typeof filterValue === 'object' && typeof datasetValue==='object' && filterName ?
        filterValue.map((item:string) => datasetValue[filterName] && datasetValue[filterName][item] && isCheckbox ? datasetValue[filterName][item] : item).join(', ') :
        (typeof filterValue === 'string' || typeof filterValue === 'number') ? filterValue : '';
    const [value, setValue] = useState<string>(prepareValue);

    const [datasetValues, setDatasetValues] = useState<any>({});

    const onChangeValue = (value: any) => {
        setValue(value);
        if(!isDelay) {
            const type = datasetValueTypes && datasetValueTypes[name] ? datasetValueTypes[name] : undefined;
            if(conditions === 'contain' || conditions === 'not_contain') {
                if(!value) {
                    onChange({value: []}, id, type);
                    return;
                }
            }
            onChange({value: [value]}, id, type);
        }
    }

    useEffect(()=> {
        if (isDelay) {
            const delay = setTimeout(() => {
                if (!isCheckbox) {
                    const type = datasetValueTypes && datasetValueTypes[name] ? datasetValueTypes[name] : undefined
                    onChange({value: [value]}, id, type);
                }

            }, 1000);
            return () => clearTimeout(delay);
        }
    }, [value])

    const onChangeValueCheckbox = (response: any) => {
        const temp: Array<any> = [];
        const total: Array<any> = [];
        for (const key in response) {
            if (response[key] && response[key].checked) {
                temp.push(response[key].name);
                total.push(key);
            }
        }
        const result = temp.join(', ');
        setValue(result ? result : trans('Value', language));
        onChange({value: total}, id);
    }
    const onChangeValueOne = (response: object) => {
        for (const value in response) {
            onChangeValue(value)
        }
    }

    const remove = () => {
        onRemove(id);
    }

    return (
        <div className="filter-dashboards-item">
            <div className="filter-dashboards-item__choice">
                <Select
                    color='dark'
                    widthAuto={true}
                    selectActive={name && datasetNameBase && (datasetNameBase as any)[name] ? (datasetNameBase as any)[name] : trans('Filter', language)}
                    dataset={datasetName}
                    onChangeActive={onChangeName}
                />
            </div>
            <div className="filter-dashboards-item__choice">
                {showLoader && <Loader inBlock={true} size={'xs'}/>}
                <Select
                    color='dark'
                    widthAuto={true}
                    selectActive={name && conditions && (datasetConditions as any)[name] && (datasetConditions as any)[name][conditions] ? (datasetConditions as any)[name][conditions] : trans('Condition', language)}
                    dataset={name && (datasetConditions as any)[name] ? (datasetConditions as any)[name] : {}}
                    onChangeActive={onChangeConditions}
                    isDisabled={!readyValue}
                />
            </div>
            {
                readyValue && name && conditions && (datasetConditions as any)[name] && (datasetConditions as any)[name][conditions] && datasetValue &&
                <div className="filter-dashboards-item__choice">
                    {
                        (typeof (datasetValue as any)[name] === 'string' || conditions ==='contain' || conditions ==='not_contain' || ((name=='campaign' || name==='name_creative') && conditions !== 'equal' && conditions !== 'not_equal')) &&
                        <Input
                            className='filter-dashboards-item__choice_input'
                            name={'value'}
                            value={value}
                            color={'dark'}
                            placeholder={trans('Value', language)}
                            onChange={(e) => onChangeValue(e.value)}
                        />
                    }
                    {
                        (typeof (datasetValue as any)[name] === 'object' || (name=='campaign' || name==='name_creative')) && conditions !== 'contain' && conditions !== 'not_contain' && isCheckbox &&
                        <Select
                            color='dark'
                            widthAuto={true}
                            checkbox={true}
                            selectActive={value ? value : trans('Value', language)}
                            dataset={datasetValues ? datasetValues : {}}
                            onChangeActive={onChangeValueCheckbox}
                            listActive={filterValue}
                            search={true}
                            className={'custom-input--search'}
                            position={valuePosition}
                            maxChecked={name !== 'campaign' ? 5: 10}
                        />
                    }
                    {
                        typeof (datasetValue as any)[name] === 'object' && conditions !== 'contain' && conditions !== 'not_contain' && !isCheckbox &&
                        <Select
                            color='dark'
                            widthAuto={true}
                            selectActive={datasetValues[value] ? datasetValues[value] : trans('Value', language)}
                            dataset={datasetValues ? datasetValues : {}}
                            onChangeActive={onChangeValueOne}
                            listActive={filterValue}
                            search={true}
                            className={'custom-input--search'}
                            position={valuePosition}
                        />
                    }
                </div>
            }
            <button onClick={remove} className='filter-dashboards-item__remove'>
                <img src={iconCart} alt=""/>
            </button>
        </div>
    )
}

export default FilterItem;