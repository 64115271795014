import './style.scss';
import Input from "../../../ui/Input";
import React, {FC, useEffect, useState} from "react";
import {DefaultObject} from "../../../interfaces/common.d";
import {trans} from "../../../_locales";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {currentUserLang} from "../../../redux/slices/user";
import Btn from "../../../ui/Btn";
import {Toggle} from "../../../ui";
import {sources} from "../../../redux/slices/sources";
import {fetchSources} from "../../../actions/source";
import {reduxRoles} from "../../../redux/slices/roles";
import {getUniqueStrings} from "../../../utils";


interface IAddSettingsRole {
    projectId: number,
    onSubmit: any,
    type: 'create' | 'edit',
    defaultData?: any,
    defaultName?: string
}

const AddSettingsRole:FC<IAddSettingsRole> = ({
    projectId,
    onSubmit,
    type,
    defaultData,
    defaultName=''
}) => {
    const dispatch = useAppDispatch();
    const language = useAppSelector(currentUserLang);
    const reduxSources = useAppSelector(sources);
    const reduxAllData = useAppSelector(reduxRoles);
    const [key, setKey] = useState<string>('');
    const [title, setTitle] = useState<string>(defaultName);
    const [form, setForm] = useState<DefaultObject<any>>({});
    const [listNames, setListNames] = useState<Array<string>>([])

    useEffect(() => {
        if(projectId) {
            dispatch(fetchSources({projectId: +projectId}));
            if(reduxAllData) {
                const temp:string[] = [];
                reduxAllData.map((item:any)=> temp.push(item.title))
                setListNames(temp);
            }
        }
    }, [projectId]);

    const handleFieldChange = (data: {[index: string]: string }) => {
        const name = Object.keys(data)[0];
        setForm({...form, ...data})
        if(name === 'name')
            setKey(data[name]);
        if(name === 'title')
            setTitle(data[name]);
    }

    const allNames = {
        can_view: 'Просмотр',
        can_create: 'Создание',
        can_edit: 'Редактирование',
        can_delete: 'Удаление',
        only_own: 'Только собственные',
        source_roles: 'Выборочно',
        show_all_in_role: 'Показывать всем в рамках роли',
        dashboard_general: 'Общие показатели',
        dashboard_ecommerce: 'Отчеты по электронной коммерции',
        dashboard_user_behavior: trans('User behavior analysis', 'ru'),
        can_delete_project: 'Удаление проекта',
        settings_general: 'Общие настройки',
        settings_attributions: 'Атрибуция и цели',
        settings_counter: 'Счетчик',
        settings_user_control: 'Управление пользователями',
        settings_integrations: 'Интеграции',
        settings_api: 'API',
        settings_roles: 'Роли'
    }

    const [campaignsCheckbox, setCampaignsCheckbox] = useState<DefaultObject<DefaultObject<any>>>({})
    const [sourceCheckbox, setSourceCheckbox] = useState<DefaultObject<DefaultObject<any>>>({})
    const [placementsCheckbox, setPlacementsCheckbox] = useState<DefaultObject<DefaultObject<any>>>({})
    const [dashboardsCheckbox, setDashboardsCheckbox] = useState<DefaultObject<DefaultObject<any>>>({})
    const [customReportsCheckbox, setCustomReportsCheckbox] = useState<DefaultObject<DefaultObject<any>>>({})
    const [eventsCheckbox, setEventsCheckbox] = useState<DefaultObject<DefaultObject<any>>>({})
    const [uploadCheckbox, setUploadCheckbox] = useState<DefaultObject<DefaultObject<any>>>({})
    const [settingsCheckbox, setSettingsCheckbox] = useState<DefaultObject<DefaultObject<any>>>({})

    const [activeCheckboxes, setActiveCheckboxes] = useState<DefaultObject<Array<string>>>({});
    const wrapper = [
        {
            name: 'campaigns',
            title: 'Кампании',
            permissions: ['can_view', 'can_create', 'can_edit', 'can_delete', 'only_own'],
            data: campaignsCheckbox,
            setData: setCampaignsCheckbox
        },
        {
            name: 'sources',
            title: 'Источники',
            permissions: ['can_view', 'can_create', 'can_edit', 'can_delete', 'only_own', 'source_roles'],
            data: sourceCheckbox,
            setData: setSourceCheckbox
        },
        // {
        //     name: 'placements',
        //     title: 'Креативы',
        //     permissions: ['can_view', 'can_create', 'can_edit', 'can_delete', 'only_own'],
        //     data: placementsCheckbox,
        //     setData: setPlacementsCheckbox
        // },
        {
            name: 'dashboards',
            title: 'Дашборды',
            permissions: ['dashboard_general', 'dashboard_ecommerce'],
            data: dashboardsCheckbox,
            setData: setDashboardsCheckbox
        },
        {
            name: 'custom_reports',
            title: 'Кастомные отчеты',
            permissions: ['can_view', 'can_create', 'can_edit', 'can_delete', 'only_own'],
            data: customReportsCheckbox,
            setData: setCustomReportsCheckbox
        },
        {
            name: 'events',
            title: 'Целевые события',
            permissions: ['can_view', 'can_create', 'can_edit', 'can_delete'],
            data: eventsCheckbox,
            setData: setEventsCheckbox
        },
        {
            name: 'upload',
            title: 'Загрузка данных',
            permissions: ['can_view', 'can_create'],
            data: uploadCheckbox,
            setData: setUploadCheckbox
        },
        {
            name: 'settings',
            title: 'Настройки',
            permissions: ['settings_general', 'settings_attributions', 'settings_counter', 'settings_user_control', 'settings_integrations', 'settings_api', 'settings_roles', 'can_delete_project'],
            data: settingsCheckbox,
            setData: setSettingsCheckbox
        }
    ]

    const onFillAll = (data: Array<any> = wrapper) => {
        const tempActives:any = {};
        data.map((item: any)=> {
            const temp = JSON.parse(JSON.stringify(item.permissions))
            if(item.name === 'sources') temp.splice(temp.indexOf('source_roles'), 1);
            tempActives[item.name] = temp;
        })
        setActiveCheckboxes(tempActives);
    }

    useEffect(() => {
        if(defaultData) {
            const tempActives:any = {};
            Object.keys(defaultData).map((key:string)=> {
                let temp = defaultData[key].access;
                if(defaultData[key].sources) {
                    temp = temp.concat(defaultData[key].sources);
                }
                tempActives[key] = getUniqueStrings(temp);
            });
            if(Array.isArray(tempActives['campaigns']) && tempActives['campaigns'].includes('show_all_in_role')) {
                setIsAgency(true);
            }
            setActiveCheckboxes(tempActives);
        }
    }, []);
    const [isAgency, setIsAgency] = useState(Array.isArray(activeCheckboxes['campaigns']) && activeCheckboxes['campaigns'].includes('show_all_in_role'));

    const onHandleSubmit = () => {
        const tempChoices = JSON.parse(JSON.stringify(activeCheckboxes));
        if(isAgency) {
            if(tempChoices['campaigns']) tempChoices['campaigns'].push('show_all_in_role');
            if(tempChoices['custom_reports']) tempChoices['custom_reports'].push('show_all_in_role');
            if(tempChoices['sources']) tempChoices['sources'].push('show_all_in_role');
        } else {
            if(Array.isArray(tempChoices['campaigns']) && tempChoices['campaigns'].includes('show_all_in_role')) {
                const index = tempChoices['campaigns'].indexOf('show_all_in_role');
                if (index !== -1) {
                  tempChoices['campaigns'].splice(index, 1);
                }
            }
            if(Array.isArray(tempChoices['custom_reports']) && tempChoices['custom_reports'].includes('show_all_in_role')) {
                const index = tempChoices['custom_reports'].indexOf('show_all_in_role');
                if (index !== -1) {
                  tempChoices['custom_reports'].splice(index, 1);
                }
            }
            if(Array.isArray(tempChoices['sources']) && tempChoices['sources'].includes('show_all_in_role')) {
                const index = tempChoices['sources'].indexOf('show_all_in_role');
                if (index !== -1) {
                  tempChoices['sources'].splice(index, 1);
                }
            }
        }
        const temp = {
            name: title,
            data: tempChoices
        }
        onSubmit(temp);
    }

    return(
        <div className='modal-settings-role'>
            <div className='modal-settings-role__block'>
                <div className='modal-settings-role__field'>
                    <Input
                        className='modal-settings-role__input'
                        name={'title'}
                        value={title}
                        label={trans('Role name', language)}
                        onChange={handleFieldChange}
                        required={true}
                        errorLabel={trans('This name already exists', language)}
                        isErrorAbsolute={true}
                        isInvalid={!!(defaultName && defaultName === title ? false : title && listNames.includes(title))}
                    />
                </div>
                <div className='modal-settings-role__actions'>
                    <Btn
                        text={trans('Select all', language)}
                        // text={trans('Full access', language)}
                        color={'light-blue'}
                        onClick={() => onFillAll()}
                        isfullWidth={true}
                    />
                    <Btn
                        text={trans('Clear', language)}
                        color={'light-blue'}
                        onClick={() => setActiveCheckboxes({})}
                        isfullWidth={true}
                    />
                </div>
            </div>

            <div className='modal-settings-role-resources'>
                <div className="modal-settings-role__checkbox">
                    <Toggle
                        name='row-choice'
                        borderMod={true}
                        isChecked={isAgency}
                        onChange={(evt) => {
                            setIsAgency(evt.currentTarget.checked);
                        }}
                        label={trans('Agency', language)}
                    />
                </div>
                {
                    wrapper.map((item: any) => (
                        <div key={item.name} className="modal-settings-role-resources__item">
                            <h4>{item.title}</h4>
                            <div className="modal-settings-role__checkboxes">
                                {
                                    item.permissions.map((key: string) => (
                                        <div key={key} className="modal-settings-role__checkbox">
                                            <Toggle
                                                name='row-choice'
                                                borderMod={true}
                                                isChecked={activeCheckboxes[item.name] && activeCheckboxes[item.name].includes(key)}
                                                onChange={(evt) => {
                                                    const tempActives = JSON.parse(JSON.stringify(activeCheckboxes));
                                                    let temp = tempActives[item.name];
                                                    if (!Array.isArray(temp)) temp = [];
                                                    if (temp.includes(key) && !evt.currentTarget.checked) {
                                                        temp.splice(temp.indexOf(key), 1);
                                                    } else if (!temp.includes(key) && evt.currentTarget.checked) {
                                                        temp.push(key);
                                                    }
                                                    tempActives[item.name] = temp;
                                                    setActiveCheckboxes(tempActives);
                                                }}
                                                label={(allNames as any)[key]}
                                            />
                                        </div>
                                    ))
                                }
                                {
                                    item.name === 'sources' && activeCheckboxes['sources'] && activeCheckboxes['sources'].includes('source_roles') && reduxSources && reduxSources.map((source) => (
                                        <div key={source.name} className="modal-settings-role__checkbox">
                                            <Toggle
                                                name='row-choice'
                                                borderMod={true}
                                                isChecked={activeCheckboxes['sources'] && activeCheckboxes['sources'].includes(source.name)}
                                                onChange={(evt) => {
                                                    const tempActives = JSON.parse(JSON.stringify(activeCheckboxes));
                                                    let temp = tempActives[item.name];
                                                    if (!Array.isArray(temp)) temp = [];
                                                    if (temp.includes(source.name) && !evt.currentTarget.checked) {
                                                        temp.splice(temp.indexOf(source.name), 1);
                                                    } else if (!temp.includes(source.name) && evt.currentTarget.checked) {
                                                        temp.push(source.name);
                                                    }
                                                    tempActives['sources'] = temp;
                                                    setActiveCheckboxes(tempActives);
                                                }}
                                                label={source.name}
                                            />
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    ))
                }
            </div>
            <Btn
                text={trans(type === 'edit' ? 'Edit' : 'Create', language)}
                color={'blue'}
                onClick={() => onHandleSubmit()}
                isfullWidth={true}
                disabled={!title || Object.keys(activeCheckboxes).filter((item: string) => activeCheckboxes[item].length > 0).length === 0 || !!(defaultName && defaultName === title ? false : title && listNames.includes(title))}
            />
        </div>
    )
}

export default AddSettingsRole;
