import React, {useEffect, useState} from "react";
import {useParams, useSearchParams, useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../../../redux/hooks";

import {setBreadcrumbs} from "../../../../../redux/slices/breadcrumbs";
import {setSidebar} from "../../../../../redux/slices/sidebar";
import {currentUser, currentUserLang, userId} from "../../../../../redux/slices/user";

import '../style.scss';

import TimeFilter from "../../../../../components/ui/TimeFilter";
import {
    getProjectAttributions,
    getProjectGeneral
} from "../../../../../actions/project";
import {
    getScreenResolution,
    isDate,
    isNumber,
    parseFilter,
    QueueServer,
    sendSdk
} from "../../../../../utils";
import cx from "classnames";
import IconFilter from "../../../../../assets/icons/icon-filter-blue.svg";
import IconFilterWhite from "../../../../../assets/icons/icon-filter-white.svg";
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/icon-close-white.svg'
import {Btn} from "../../../../../ui";
import {closeModal, ModalName, openModal} from "../../../../../redux/slices/modals";
import {
    clearFilter, dashboardsSettings, setFilter as setDispatchFilter, setSetting, setSettingPeriod
} from "../../../../../redux/slices/dashboards";
import DashboardsTable from "../components/Table";
import {trans} from "../../../../../_locales";
import {onChangeFilterQuery, onLoadingSelectDate, validateModel, validateScale} from "../functions";
import {clearInstructions} from "../../../../../redux/slices/instructions";
import {getPages} from "../../../../../components/SideMenu/Role";
import {IFilterMediaArray} from "../../../../../components/Tables/interfaces";
import {IModelAttribution, IWindowAttribution} from "../../../../../interfaces/common.d";

const DashboardPostViewSessions = () => {
    const dispatch = useAppDispatch();
    const { projectId } = useParams();
    const reduxUser =  useAppSelector(currentUser);
    const reduxUserId =  useAppSelector(userId);
    const language = useAppSelector(currentUserLang);
    const settings = useAppSelector(dashboardsSettings);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const permissions = reduxUser && reduxUser.permission ? reduxUser.permission[`${projectId}`] : undefined;
    const isViewGeneral = permissions && permissions['can_view'] && permissions['can_view'].includes('dashboard_general');
    const isViewEcom = permissions && permissions['can_view'] && permissions['can_view'].includes('dashboard_ecommerce');
    const isViewUserBehavior = permissions && permissions['can_view'] && permissions['can_view'].includes('dashboard_user_behavior');

    const [firstLoading, setFirstLoading] = useState(true);
    const defaultPeriodStart = searchParams.get("ps") || '';
    const defaultPeriodEnd = searchParams.get("pe") || '';
    const defaultFilter = searchParams.get("f") || '';

    useEffect(()=> {
        if(projectId && reduxUserId) {
            if(+projectId !== 12523) {
                navigate(`/projects/${projectId}/reports/`)
            }
            sendSdk('page_view', {'user': {'uid': reduxUserId},'event_params': {'project_id': projectId, 'user_id': reduxUserId, 'page': 'postview_sessions', 'resolution': getScreenResolution()}})
        }
    }, [projectId, reduxUserId])

    useEffect(() => {
        const tempBreadcrumbs:any = [
            {
              href: '/projects/',
              title: 'Проект',
            },
            {
              href: `/projects/${projectId}/reports/`,
              title: trans('Project reporting', language)
            },
            {
              href: `#`,
              title: trans('PostView Sessions', language),
              dataset: {}
            }
        ]
        if(isViewGeneral || (projectId && (+projectId === 12475))) {
            tempBreadcrumbs[2].dataset['general'] = {
                title: trans('General indicators', language),
                href: `/projects/${projectId}/reports/general/`
            }
        }
        if(isViewEcom || (projectId && (+projectId === 12475))) {
            tempBreadcrumbs[2].dataset['ecommerce'] = {
                title: trans('E-Commerce Reports', language),
                href: `/projects/${projectId}/reports/ecommerce/`
            }
        }
        if(isViewUserBehavior || (projectId && (+projectId === 12475))) {
            tempBreadcrumbs[2].dataset['user_behavior'] = {
                title: trans('User behavior analysis', language),
                href: `/projects/${projectId}/reports/user_behavior/`
            }
        }
        if(projectId && (+projectId === 12484 || +projectId===12483)) {
            tempBreadcrumbs[2].dataset['getintent'] = {
                title: 'GetIntent',
                href: `/projects/${projectId}/reports/getintent/`
            }
        }
        dispatch(setBreadcrumbs(tempBreadcrumbs));
        dispatch(clearInstructions());
        dispatch(setSidebar(getPages({
            projectId: Number(projectId),
            access: permissions && permissions['can_view'],
            language,
            active: 'dashboards'
        })))
        dispatch(getProjectGeneral({currentUserId: reduxUser.id,projectId: Number(projectId)}));
    }, [dispatch, projectId, permissions]);

    const contentClassName = cx('page-content__content', 'page-content__content_scroll', 'page-content__shadow');

    //-------Filter--------
    const [periodStart, setPeriodStart] = useState(isDate(defaultPeriodStart) ? defaultPeriodStart : '');
    const [periodEnd, setPeriodEnd] = useState(isDate(defaultPeriodEnd) ? defaultPeriodEnd : '');
    const [selectDate, setSelectDate] = useState<string>(isDate(defaultPeriodStart)&&isDate(defaultPeriodEnd) ? `${defaultPeriodStart} - ${defaultPeriodEnd}` : trans('Select the period', language));

    useEffect(()=> {
        if(settings && Object.keys(settings).length > 0) {
            if (sessionStorage.getItem("settings")) {
                const sessionSettings = sessionStorage.getItem("settings");
                const parsed = sessionSettings && JSON.parse(sessionSettings);
                const temp = {
                    period: settings.period ? settings.period : parsed.period
                }
                sessionStorage.setItem("settings", JSON.stringify(temp));
            } else sessionStorage.setItem("settings", JSON.stringify(settings));
        } else if(sessionStorage.getItem("settings")) {
            const sessionSettings = sessionStorage.getItem("settings");
            const parsed = sessionSettings && JSON.parse(sessionSettings);
            parsed && dispatch(setSetting(parsed));
        }
    }, [])

    const onChangeSelectDate = (response:any) => {
        setPeriodStart(response.start);
        setPeriodEnd(response.end);
        setSelectDate(`${response.start} - ${response.end}`);
        dispatch(setSettingPeriod({
            start: response.start,
            end: response.end
        }))
    }

    useEffect(() => {
        if(firstLoading) {
            setFirstLoading(false);
            dispatch(getProjectAttributions({currentUserId: reduxUser.id, projectId: Number(projectId)})).then((r)=> {
                if(r.payload) {
                    const sessionSettings = sessionStorage.getItem("settings");
                    const parseSettings = sessionSettings && JSON.parse(sessionSettings);
                    if(isDate(defaultPeriodStart) && isDate(defaultPeriodEnd)) {
                        onChangeSelectDate({start: defaultPeriodStart, end: defaultPeriodEnd});
                    } else if (parseSettings && parseSettings.period) {
                        onChangeSelectDate({start: parseSettings.period.start, end: parseSettings.period.end});
                    } else
                        onLoadingSelectDate({period: r.payload.attribution_period, setPeriodStart, setPeriodEnd, setValue: setSelectDate});
                }
            }).catch((e: any)=> {
                const sessionSettings = sessionStorage.getItem("settings");
                const parseSettings = sessionSettings && JSON.parse(sessionSettings);
                if (isDate(defaultPeriodStart) && isDate(defaultPeriodEnd)) {
                    setPeriodStart(defaultPeriodStart);
                    setPeriodEnd(defaultPeriodEnd);
                    setSelectDate(`${defaultPeriodStart} - ${defaultPeriodEnd}`);
                } else if (parseSettings && parseSettings.period) {
                    setPeriodStart(parseSettings.period.start);
                    setPeriodEnd(parseSettings.period.end);
                    setSelectDate(`${parseSettings.period.start} - ${parseSettings.period.end}`);
                } else
                    onLoadingSelectDate({period: 'month', setPeriodStart, setPeriodEnd, setValue: setSelectDate});
            })
            if(defaultFilter) {
                const temp:IFilterMediaArray = parseFilter(defaultFilter, '^*^');
                onSubmitFilter(temp);
                dispatch(setDispatchFilter(temp));
            }
        }
    }, [projectId, reduxUser.id])

    // ------Filter--------
    const [filter, setFilter] = useState<IFilterMediaArray>([]);
    const [filterActive, setFilterActive] = useState(false);
    const onResetFilter = () => {
        dispatch(clearFilter());
        setFilter([]);
        setFilterActive(false);
    }
    const onSubmitFilter = (response:IFilterMediaArray) => {
        setFilter(response);
        if(response.length > 0) {
            setFilterActive(true);
            sendSdk('event', {
                'event_type': 'filter',
                'event_category': 'campaign_efficiency',
                'event_name': 'active',
                'event_value': '10',
                'event_params': {
                    'project_id': projectId,
                    'user_id': reduxUserId
                }
            });
        }
        else setFilterActive(false);
        dispatch(closeModal(ModalName.FILTER))
    }
    //-------End Filter--------

    const queue = new QueueServer();

    const onChangeQuery = ({
        periodStart, periodEnd, model, attr_window, scale, eventTargetActive, filter
    }:{
        periodStart?: string,
        periodEnd?: string,
        model?: IModelAttribution,
        attr_window?: IWindowAttribution,
        scale?: string,
        eventTargetActive?: Array<string>,
        filter?: IFilterMediaArray
    }) => {
        const query = onChangeFilterQuery({periodStart, periodEnd, model, attr_window, scale, eventTargetActive, filter});
        const temp = {
            search: query
        }
        navigate(temp);
    };

    useEffect(()=> {
        if(!firstLoading) {
            onChangeQuery({
                periodStart,
                periodEnd,
                filter
            });
        }
    }, [periodStart, periodEnd, filter])

  return (
    <div className={contentClassName} onScroll={()=>null}>
        <div className="dashboard-page-filter">
            <div className="dashboard-page-filter__date">
              <TimeFilter selectActive={selectDate} onChange={onChangeSelectDate}/>
            </div>
            <div className="dashboard-page-filter__auto">
              <button className={`dashboard-page-filter__btn ${filterActive?'active':''}`} onClick={()=>{
                  projectId && dispatch(openModal({
                      name: ModalName.FILTER,
                      data: {
                        onSubmit: onSubmitFilter,
                        projectId: +projectId
                      }
                  }))
              }}>
                  <img src={filterActive ? IconFilterWhite : IconFilter} alt=""/>
                  {trans('Filter', language)}
              </button>
              { filterActive && <Btn
                  className={'dashboard-page-filter__reset'}
                  isIconBtn={true}
                  size='sm'
                  color='transparent'
                  onClick={onResetFilter}
                  icon={{
                    Component: CloseIcon,
                    width: 16,
                    height: 16
                  }}
              /> }
            </div>
        </div>

        <div className="dashboard-page-summary"></div>

        <div className="dashboard-page-table">
            <div className="dashboard-page__table">
                <DashboardsTable
                    name={'postview_table_postview'}
                    projectId={Number(projectId)}
                    periodStart={periodStart}
                    periodEnd={periodEnd}
                    selectDate={selectDate}
                    filter={filter}
                    queue={queue}
                    hideTotal={true}
                />
            </div>
        </div>
    </div>
  )
}

export default DashboardPostViewSessions;