import React, {useEffect, useState} from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../../../redux/hooks";

import {setBreadcrumbs} from "../../../../../redux/slices/breadcrumbs";
import {setSidebar} from "../../../../../redux/slices/sidebar";
import {currentUser, currentUserLang, userId} from "../../../../../redux/slices/user";
import {closeModal, ModalName, openModal} from "../../../../../redux/slices/modals";

import {trans} from "../../../../../_locales";
import {getProjectAttributions, getProjectGeneral} from "../../../../../actions/project";
import TimeFilter from "../../../../../components/ui/TimeFilter";
import Select from "../../../../../components/ui/Select";
import iconModel from "../../../../../assets/icons/model.svg";
import iconWindow from "../../../../../assets/icons/window.svg";
import iconPeriod from "../../../../../assets/icons/period.svg";
import iconTarget from "../../../../../assets/icons/target-icon.svg";
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/icon-close-white.svg'
import IconFilterWhite from "../../../../../assets/icons/icon-filter-white.svg";
import IconFilter from "../../../../../assets/icons/icon-filter-blue.svg";
import {Btn} from "../../../../../ui";
import DashboardsTable from "../../Dashboards/components/Table";
import cx from "classnames";
import {
    getFromObject,
    getInterval, getScreenResolution, isDate, isNumber,
    parseCustomFilter,
    parseFilter,
    parseSort,
    removeObjectKeys,
    sendSdk
} from "../../../../../utils";
import './style.scss';
import {getReport} from "../../../../../actions/reports";
import Loader from "../../../../../components/Loader";
import {
    onChangeFilterQuery,
    onLoadingSelectDate,
    validateModel,
    validateScale,
    validateWindow
} from "../../Dashboards/functions";
import {eventsData, ICurrentEvents} from "../../../../../redux/slices/events";
import {getEvents} from "../../../../../actions/events";
import {Groups, Models, Windows} from "../../../../../data";
import {getSelectChangesTitle, onChangeGeneralSelect} from "../../../../../components/ui/Select/functions";
import {clearInstructions} from "../../../../../redux/slices/instructions";
import {clearFilter, setFilter as setDispatchFilter, setSettingEventTarget} from "../../../../../redux/slices/dashboards";
import {ICustomReport} from "../../../../../components/ReportsUpdate/interfaces.d";
import {getPages} from "../../../../../components/SideMenu/Role";
import {DefaultObject, IModelAttribution, IWindowAttribution} from "../../../../../interfaces/common.d";
import {ListEventAgg, ListEventMetrics, ListMediaAgg, ListMediaMetrics} from "../data/row";
import {getEventTitles} from "../functions";
import {IFilterMediaArray} from "../../../../../components/Tables/interfaces";


const ReportsView = () => {
    const dispatch = useAppDispatch();
    const { projectId, reportId } = useParams();
    const reduxUser =  useAppSelector(currentUser);
    const reduxUserId =  useAppSelector(userId);
    const reduxEvents = useAppSelector(eventsData);
    const language = useAppSelector(currentUserLang);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const role:string = reduxUser && reduxUser.roles ? reduxUser.roles[`${projectId}`] : "";
    const permissions = reduxUser && reduxUser.permission ? reduxUser.permission[`${projectId}`] : undefined;
    // const dashboard = 'custom_table_domain';
    // const dashboard = 'custom_table_full_stat';
    const [dashboard, setDashboard] = useState<string>('custom_table_main');
    useEffect(()=> {
        if (projectId && reduxUserId && reportId) {
            sendSdk('page_view', {
                'user': {
                    'uid': reduxUserId
                },
                'event_params': {
                    'project_id': projectId,
                    'user_id': reduxUserId,
                    'page': 'custom_reports_view',
                    'custom_report_id': reportId,
                    'resolution': getScreenResolution()
                }
            })
        }
        const isView = permissions && permissions['can_view'] && permissions['can_view'].includes('custom_reports');
        const isCreate = permissions && permissions['can_create'] && permissions['can_create'].includes('custom_reports');
        const isEdit = permissions && permissions['can_edit'] && permissions['can_edit'].includes('custom_reports');
        const isDelete = permissions && permissions['can_delete'] && permissions['can_delete'].includes('custom_reports');

        if(permissions && !isView) {
            navigate(`/projects/${projectId}/custom-reports/`)
        }
        dispatch(setSidebar(getPages({
            projectId: Number(projectId),
            access: permissions && permissions['can_view'],
            language,
            active: 'custom_reports'
        })))
    }, [projectId, reduxUserId, reportId, permissions])

    useEffect(() => {
        dispatch(clearInstructions());
        dispatch(setBreadcrumbs([
            {
                href: '/projects/',
                title: 'Проект',
            },
            {
                href: `/projects/${projectId}/custom-reports/`,
                title: trans('Custom reports', language)
            },
            {
                href: `/projects/${projectId}/custom-reports/view/`,
                title: trans('Preview', language)
            }
        ]))
        dispatch(setSidebar(getPages({
            projectId: Number(projectId),
            access: permissions && permissions['can_view'],
            language,
            active: 'custom_reports'
        })))
        dispatch(getEvents({projectId, calcAttr: false})).then((r) => {
            if (r.payload && typeof r.payload.length === 'number' && Array.isArray(r.payload)) {
                const temp:any = {'all_target_events': trans('All event targets', language)};
                const tempAllEvents: DefaultObject<string> = {};
                r.payload.map((item:any)=> {
                    if(item.calculate_attribution_models)
                        temp[item.event_target_name] = item.event_target_name;
                    tempAllEvents[item.event_target_name] = item.event_target_name;
                });
                setDatasetEventTarget(temp);
                setDatasetAllEventTarget(tempAllEvents);
                setAllEventTarget(r.payload);
                const sessionSettings = sessionStorage.getItem("settings");
                const parseSettings = sessionSettings && JSON.parse(sessionSettings);
                if(parseSettings && Array.isArray(parseSettings.events)) {
                    const {title, keys} = getEventTitles(parseSettings.events);
                    setEventTarget(title);
                    setEventTargetActive(keys);
                    setFilterEventTarget(parseSettings.events)
                }
                setEventReady(true);
            }
        });
        dispatch(getProjectGeneral({currentUserId: reduxUser.id,projectId: Number(projectId)}));
    }, [dispatch, language, projectId, role]);

    const contentClassName = cx('page-content__content', 'page-content__content_scroll', 'page-content__shadow', 'custom-report-view');

    const [firstLoading, setFirstLoading] = useState(true);

    //-------Filter--------
    const defaultPeriodStart = searchParams.get("ps") || '';
    const defaultPeriodEnd = searchParams.get("pe") || '';
    const defaultModel = searchParams.get("m") || '';
    const defaultWindow = searchParams.get("w") || '';
    const defaultScale = searchParams.get("s") || '';
    const defaultEventTargetActive = searchParams.get("et") || '';
    const defaultQueryFilter = searchParams.get("f") || '';
    const [periodStart, setPeriodStart] = useState(isDate(defaultPeriodStart) ? defaultPeriodStart : '');
    const [periodEnd, setPeriodEnd] = useState(isDate(defaultPeriodEnd) ? defaultPeriodEnd : '');
    const [categorize, setCategorize] = useState(validateScale(defaultScale, 'day'));
    const [selectDate, setSelectDate] = useState<string>(isDate(defaultPeriodStart)&&isDate(defaultPeriodEnd) ? `${defaultPeriodStart} - ${defaultPeriodEnd}` : trans('Select the period', language));
    const [serverNoActual, setServerNoActual] = useState(false);
    const [model, setModel] = useState(validateModel(defaultModel, 'mtd'));
    const [attribution_window, setWindow] = useState<IWindowAttribution>(validateWindow(defaultWindow, 30) || 30 as IWindowAttribution);
    const [eventTarget, setEventTarget] = useState('');

    const datasetCategorize = Groups(language);
    const onChangeSelectDate = (response:any) => {
        setPeriodStart(response.start);
        setPeriodEnd(response.end);
        setSelectDate(`${response.start} - ${response.end}`);
    }

    const datasetModel = Models(language);
    const [datasetWindow, setDatasetWindow] = useState(Windows(language, model));

    const [allEventTarget, setAllEventTarget] = useState<Array<ICurrentEvents>>( [])
    const [datasetEventTarget, setDatasetEventTarget] = useState<DefaultObject<string>>( {'all_target_events': trans('All event targets', language)})
    const [datasetAllEventTarget, setDatasetAllEventTarget] = useState<DefaultObject<string>>( {})
    const [filterEventTarget, setFilterEventTarget] = useState<Array<ICurrentEvents>>( []);
    const [eventTargetActive, setEventTargetActive] = useState<Array<string>>([]);
    const [eventReady, setEventReady] = useState<boolean>(false);
    const onChangeEventTarget = (response:object) => {
        const {title, listActive} = getSelectChangesTitle(response, trans('All event targets', language));
        setEventTarget(title);
        setEventTargetActive(listActive)
    }

    useEffect(()=>{
        if(allEventTarget && allEventTarget.length > 0) {
            const result:Array<ICurrentEvents> = [];
            allEventTarget.map((item:any)=> {
                const temp: ICurrentEvents = {} as ICurrentEvents;
                if(eventTargetActive.includes(item.event_target_name)) {
                    temp.event_name = item.event_name;
                    temp.event_type = item.event_type;
                    temp.event_category = item.event_category;
                    temp.event_target_name = item.event_target_name;
                    temp.event_target_price = item.event_target_price;
                    result.push(temp);
                }
            })
            dispatch(setSettingEventTarget(result));
            setFilterEventTarget(result);
        }
    }, [datasetAllEventTarget, eventTarget, eventTargetActive])


    const [data, setData] = useState<any>();
    const [dataReady, setDataReady] = useState<boolean>(false);
    const [defaultAgg, setDefaultAgg] = useState<Array<string>>([]);
    const [defaultFilter, setDefaultFilter] = useState<Array<any>>([]);
    const [defaultSort, setDefaultSort] = useState<Array<any>>([]);
    const [defaultMetric, setDefaultMetric] = useState<Array<any>>([]);
    const [defaultEventTarget, setDefaultEventTarget] = useState<any>({});

    const [customFilters, setCustomFilters] = useState<Array<any>>([]);
    const [customTrgFilters, setCustomTrgFilters] = useState<Array<any>>([]);
    const [ecomFilters, setEcomFilters] = useState<Array<any>>([]);
    const [extendFilters, setExtendFilters] = useState<Array<any>>([]);
    const [interactionType, setInteractionType] = useState<ICustomReport["interaction_type"]>(['Impression'])
    const [trgPeriodStart, setTrgPeriodStart] = useState<string>('');
    const [trgPeriodEnd, setTrgPeriodEnd] = useState<string>('');

    useEffect(() => {
        if(firstLoading && reduxEvents && !dataReady && eventReady) {
            setFirstLoading(false);
            let model;
            if(validateModel(defaultModel)) {
                model = defaultModel;
                setModel(defaultModel)
            }
            if(validateWindow(defaultWindow, undefined, model as IModelAttribution | undefined)) {
                setWindow(+defaultWindow as IWindowAttribution)
            }
            if(validateScale(defaultScale)) {
                setCategorize(defaultScale)
            }
            if(isDate(defaultPeriodStart) && isDate(defaultPeriodEnd)) {
                onChangeSelectDate({
                    start: defaultPeriodStart,
                    end: defaultPeriodEnd
                })
            }
            dispatch(getProjectAttributions({currentUserId: reduxUser.id, projectId: Number(projectId)})).then((r)=> {
              if(r.payload && !(isDate(defaultPeriodStart) && isDate(defaultPeriodEnd))) {
                const data = onLoadingSelectDate({period: r.payload.attribution_period, type: 'return'});
                if(data) {
                    onChangeSelectDate(data);
                }
              }
            })
            dispatch(getReport({ projectId: Number(projectId), reportId: Number(reportId)})).then((r)=> {
                if (r.payload) {
                    const flags = {
                        media: {
                            agg: false,
                            metrics: false,
                            only: false
                        },
                        event: {
                            agg: false,
                            metrics: false,
                            only: false
                        }
                    }
                    if(r.payload.fields && typeof r.payload.fields === 'string') {
                        const result = r.payload.fields.split(',');
                        result.map((item:string) => {
                            if(ListMediaAgg.includes(item) || item.startsWith('custommedia_')) flags.media.agg = true;
                            if(ListEventAgg.includes(item) || item.startsWith('customevent_')) flags.event.agg = true;
                        })
                        setDefaultAgg(result);
                    }
                    if(r.payload.metrics && typeof r.payload.metrics === 'string') {
                        const result = r.payload.metrics.split(',').map((item: string) => {
                            if(item.startsWith('eventitem_')) {
                                if(allEventTarget.length > 0) {
                                    const tempName = item;
                                    const prefix = 'eventitem_';
                                    const event_id = tempName.slice(prefix.length);
                                    if(isNumber(event_id)) {
                                        let temp: string = '';
                                        const ampersand = '*^*';
                                        allEventTarget.map((event: any) => {
                                            if (event.id === +event_id) {
                                                temp = `${prefix}${event.event_type}${ampersand}${event.event_category}${ampersand}${event.event_name}${ampersand}${event.event_target_name}`;
                                                return;
                                            }
                                        })
                                        if(temp) return temp;
                                    }
                                }
                                return;
                            }
                            return item;
                        }).filter((item: string) => item);
                        result.map((item:string) => {
                            if(ListMediaMetrics.includes(item)) flags.media.metrics = true;
                            if(ListEventMetrics.includes(item) || item.startsWith('eventitem_')) flags.event.metrics = true;
                        })
                        setDefaultMetric(result)
                    }
                    if(r.payload.filter_fields) {
                        const temp = parseFilter(r.payload.filter_fields);
                        const result = temp.map((item) => {
                            const tempItem = JSON.parse(JSON.stringify(item));
                            if (tempItem.name.startsWith('eventitem_')) {
                                if(allEventTarget.length > 0) {
                                    const prefix = 'eventitem_';
                                    const event_id = tempItem.name.slice(prefix.length);
                                    if (isNumber(event_id)) {
                                        const ampersand = '*^*';
                                        allEventTarget.map((event: any) => {
                                            if (event.id === +event_id) {
                                                tempItem.name = `${prefix}${event.event_type}${ampersand}${event.event_category}${ampersand}${event.event_name}${ampersand}${event.event_target_name}`;
                                                return;
                                            }
                                        })
                                    }
                                } else return;
                            }
                            return tempItem;
                        }).filter((item: string) => item);
                        setCustomFilters(result);
                    }
                    if (r.payload.filter_ecom) {
                        setEcomFilters(parseCustomFilter(r.payload.filter_ecom));
                    }
                    if(defaultQueryFilter) {
                        const temp: IFilterMediaArray = parseFilter(defaultQueryFilter, '^*^', '^^^');
                        setFilter(temp);
                        setDefaultFilter(temp);
                        dispatch(setDispatchFilter(temp));
                        setFilterActive(true);
                    } else if (r.payload.filter) {
                        const temp = parseFilter(r.payload.filter, '&', '|');
                        setFilter(temp);
                        setDefaultFilter(temp);
                        dispatch(setDispatchFilter(temp));
                        setFilterActive(true);
                    }
                    if (r.payload.filter_trg) {
                        setCustomTrgFilters(parseFilter(r.payload.filter_trg));
                    }
                    if(r.payload.interaction_type) {
                        const temp:any = r.payload.interaction_type;
                        const list = temp.split('&')
                        setInteractionType(list);
                    }
                    setData(r.payload);
                    if(r.payload.type && r.payload.type === 'general_custom_table') {
                        setDashboard('custom_table_general')
                    }
                    if(r.payload.type && r.payload.type === 'ecommerce') {
                        setDashboard('custom_table_ecommerce_items')
                    }

                    if(r.payload.type && r.payload.type === 'media_campaign_effectivity') {
                        flags.media.only = flags.media.agg && flags.media.metrics && !flags.event.agg && !flags.event.metrics;
                        flags.event.only = !flags.media.metrics && (flags.event.agg || flags.media.agg) && flags.event.metrics;

                        if(flags.media.only) {
                            setDashboard('custom_table_row_media');
                        }
                        if(flags.event.only) {
                            setDashboard('custom_table_row_event');
                        }
                        if(!flags.media.only && !flags.event.only) {
                            setDashboard('custom_table_row');
                        }
                    }
                    if(r.payload.sort) {
                        const temp = parseSort(r.payload.sort);
                        const result = temp.map((item) => {
                            const tempItem = JSON.parse(JSON.stringify(item));
                            if (tempItem.name.startsWith('eventitem_')) {
                                if(allEventTarget.length > 0) {
                                    const prefix = 'eventitem_';
                                    const event_id = tempItem.name.slice(prefix.length);
                                    if (isNumber(event_id)) {
                                        const ampersand = '*^*';
                                        let isFindEvent = false;
                                        allEventTarget.map((event: any) => {
                                            if (event.id === +event_id) {
                                                isFindEvent = true;
                                                tempItem.name = `${prefix}${event.event_type}${ampersand}${event.event_category}${ampersand}${event.event_name}${ampersand}${event.event_target_name}`;
                                                return;
                                            }
                                        })
                                        if(!isFindEvent) return;
                                    } else return;
                                } else return;
                            }
                            return tempItem;
                        }).filter((item: string) => item);
                        setDefaultSort(result);
                    }
                    if(defaultEventTargetActive) {
                        let name = defaultEventTargetActive;
                        if(name === 'all_target_events') {
                            name = trans('All event targets', language)
                        }
                        setEventTarget(name);
                        setEventTargetActive(defaultEventTargetActive.split(','))
                    } else if(r.payload.event_target) {
                        setEventTargetActive(r.payload.event_target.split(','));
                        let name = r.payload.event_target;
                        if(name === 'all_target_events') {
                            name = trans('All event targets', language)
                        }
                        setEventTarget(name);
                    }
                    if(!validateWindow(defaultWindow)) {
                        const winTemp = validateWindow(r.payload.attribution_window, 30);
                        if(winTemp) setWindow(+winTemp as IWindowAttribution);
                    }
                    if(!validateModel(defaultModel))
                        setModel(validateModel(r.payload.attribution_model, 'fl'));
                    if(!validateScale(defaultScale))
                        setCategorize(validateScale(r.payload.grouping, 'day'));
                    // const interval = r.payload.period !== 'choice' ? getInterval(new Date(), r.payload.period) : {interval_from: r.payload.interval_from, interval_to: r.payload.interval_to};
                    const interval = r.payload.period !== 'choice' ? onLoadingSelectDate({period: r.payload.period, type: 'return'}) : {start: r.payload.interval_from, end: r.payload.interval_to};
                    if(interval && !(isDate(defaultPeriodStart) && isDate(defaultPeriodEnd))) {
                        onChangeSelectDate({
                            start: interval.start,
                            end: interval.end
                        });
                    }
                    if(r.payload.trg_period || (r.payload.trg_interval_from && r.payload.trg_interval_to)) {
                       // const trgInterval = r.payload.trg_period !== 'choice' && r.payload.trg_period !== 'none' ? getInterval(new Date(), r.payload.trg_period) : {interval_from: r.payload.trg_interval_from, interval_to: r.payload.trg_interval_to};
                       const trgInterval = r.payload.trg_period !== 'choice' && r.payload.trg_period !== 'none' ? onLoadingSelectDate({period: r.payload.trg_period, type: 'return'}) : {start: r.payload.trg_interval_from, end: r.payload.trg_interval_to};
                        if(trgInterval) {
                            setTrgPeriodStart(trgInterval.start);
                            setTrgPeriodEnd(trgInterval.end);
                        }
                    }

                }
            }).then(()=>{
                setTimeout(()=> {
                    setDataReady(true)
                }, 500);
            });
        }
    }, [projectId, reduxUser.id, reportId, reduxEvents, eventReady])

    // ------Filter--------
    const [filter, setFilter] = useState<Array<any>>([]);
    const [filterActive, setFilterActive] = useState(false);
    const onResetFilter = () => {
        dispatch(clearFilter());
        setFilter([]);
        setFilterActive(false);
    }
    const onSubmitFilter = (response:any) => {
        setFilter(response);
        if(response.length > 0) {
            setFilterActive(true);
            sendSdk('event', {
                'event_type': 'filter',
                'event_category': 'view_custom_report',
                'event_name': 'active',
                'event_value': '10',
                'event_params': {
                    'project_id': projectId,
                    'user_id': reduxUserId
                }
            });
        }
        else setFilterActive(false);
        dispatch(closeModal(ModalName.FILTER))
    }
    //-------End Filter--------

    const onChangeQuery = ({
        periodStart, periodEnd, model, attr_window, scale, eventTargetActive, filter
    }:{
        periodStart?: string,
        periodEnd?: string,
        model?: IModelAttribution,
        attr_window?: IWindowAttribution,
        scale?: string,
        eventTargetActive?: Array<string>,
        filter?: IFilterMediaArray
    }) => {
        const query = onChangeFilterQuery({periodStart, periodEnd, model, attr_window, scale, eventTargetActive, filter, sepValue: '^^^'});
        const temp = {
            search: query
        }
        navigate(temp);
    };

    useEffect(()=> {
        if(!firstLoading) {
            onChangeQuery({
                periodStart,
                periodEnd,
                model,
                attr_window: attribution_window as IWindowAttribution,
                scale: categorize,
                eventTargetActive,
                filter
            });
        }
    }, [periodStart, periodEnd, model, attribution_window, categorize, eventTargetActive, filter])

    useEffect(() => {
        if(validateModel(model)) {
            setDatasetWindow(Windows(language, model));
            if(!validateWindow(attribution_window, undefined, model)) {
                setWindow(30);
            }
        }
    }, [model]);

    return(
        <div className={contentClassName}>
            <div className="dashboard-page-filter static">
                <div className="dashboard-page-filter__date">
                    <TimeFilter selectActive={selectDate} onChange={onChangeSelectDate}/>
                </div>
                {data && (data.type === 'general_custom_table' || data.type === 'ecommerce' || data.type === 'media_campaign_effectivity') &&
                    <div className="dashboard-page-filter__select">
                        <Select selectActive={(datasetModel as any)[model]} icon={iconModel} dataset={datasetModel}
                                onChangeActive={(res: object) => onChangeGeneralSelect(res, setModel)}/>
                    </div>}
                {data && (data.type === 'general_custom_table' || data.type === 'ecommerce' || data.type === 'media_campaign_effectivity') &&
                    <div className="dashboard-page-filter__select">
                        <Select
                            selectActive={`${trans('Attribution window', language)}: ${attribution_window} ${trans('days', language)}`}
                            icon={iconWindow} dataset={datasetWindow}
                            onChangeActive={(res: object) => onChangeGeneralSelect(res, (value: any) => setWindow(+value as IWindowAttribution))}/>
                    </div>}
                <div className="dashboard-page-filter__select">
                    <Select selectActive={`${trans('Scale', language)}: ${(datasetCategorize as any)[categorize]}`}
                            icon={iconPeriod} dataset={datasetCategorize}
                            onChangeActive={(res: object) => onChangeGeneralSelect(res, setCategorize)}/>
                </div>
                {data && (data.type === 'general_custom_table') && <div className="dashboard-page-filter__select">
                    <Select
                        selectActive={(datasetEventTarget as any)[eventTarget] ? (datasetEventTarget as any)[eventTarget] : trans('All event targets', language)}
                        icon={iconTarget}
                        dataset={datasetEventTarget}
                        onChangeActive={onChangeEventTarget}/>
                </div>}
                {
                    data && data.type === 'media_campaign_effectivity' &&
                    <div className="dashboard-page-filter__select">
                        <Select
                            selectActive={eventTarget ? eventTarget : trans('All event targets', language)}
                            icon={iconTarget}
                            dataset={datasetAllEventTarget}
                            onChangeActive={onChangeEventTarget}
                            checkbox={true}
                            listActive={eventTargetActive}
                            isSelectAllBtn={true}
                        />
                    </div>
                }
                {<div className="dashboard-page-filter__auto">
                    <button className={`dashboard-page-filter__btn ${filterActive ? 'active' : ''}`} onClick={() => {
                        projectId && dispatch(openModal({
                            name: ModalName.FILTER,
                            data: {
                                onSubmit: onSubmitFilter,
                                projectId: +projectId
                            }
                        }))
                    }}>
                        <img src={filterActive ? IconFilterWhite : IconFilter} alt=""/>
                        {trans('Filter', language)}
                    </button>
                    {filterActive && <Btn
                        className={'dashboard-page-filter__reset'}
                        isIconBtn={true}
                        size='sm'
                        color='transparent'
                        onClick={onResetFilter}
                        icon={{
                            Component: CloseIcon,
                            width: 16,
                            height: 16
                        }}
                    />}
                </div>}
            </div>
            <div className="dashboard-page-table">
                <div className="dashboard-page__table">
                    {dataReady && data ? <DashboardsTable
                            name={dashboard}
                            type={data.type}
                            defaultName={data.name}
                            projectId={Number(projectId)}
                            periodStart={periodStart}
                            periodEnd={periodEnd}
                            categorize={categorize}
                            window={attribution_window}
                            model={model}
                            filter={filter}
                            targetEvents={filterEventTarget}
                            serverGroup={true}
                            noActual={serverNoActual}
                            setActual={setServerNoActual}
                            offAgg={true}
                            defaultAgg={defaultAgg}
                            defaultFilter={defaultFilter}
                            customFilters={customFilters}
                            defaultSort={defaultSort}
                            metricInclude={true}
                            defaultMetric={defaultMetric}
                            defaultRowsOnPage={30}
                            isDefaultRequest={false}
                            customTrgFilters={customTrgFilters}
                            ecomFilters={ecomFilters}
                            interactionType={interactionType}
                            trgPeriodStart={trgPeriodStart}
                            trgPeriodEnd={trgPeriodEnd}
                    /> :
                    <Loader inBlock={true} isNotTransparent={true}/>}
                </div>
            </div>
        </div>
    )

}

export default ReportsView;