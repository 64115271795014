import './style.scss';
import {FC} from "react";
import cx from 'classnames'
import {Btn} from "../../ui";
import { ReactComponent as CloseIcon } from '../../assets/icons/icon-close.svg'
import {trans} from "../../_locales";
import {useAppSelector} from "../../redux/hooks";
import {currentUserLang} from "../../redux/slices/user";


interface IMultipleActions {
    className?: string,
    data?: any,
    count: number,
    onDownload: () => void,
    onDownloadTemplate: () => void,
    onDelete: () => void,
    onReset: () => void,
    onChange: () => void,
    isEdit: boolean,
    isDelete: boolean
    type?: 'campaigns' | 'placements'
}


const MultipleActions:FC<IMultipleActions> = ({
    data,
    type='campaigns',
    className='',
    count,
    onDownload,
    onDownloadTemplate,
    onDelete,
    onReset,
    onChange,
    isEdit=false,
    isDelete=false
}) => {
    const language = useAppSelector(currentUserLang);
    const choices = {
        'campaigns': {
            'one': 'кампанию',
            'short': 'кампании',
            'many': 'кампаний'
        },
        'placements': {
            'one': 'креатив',
            'short': 'креатива',
            'many': 'креативов'
        }
    }
    return (<div className={cx('multi-actions', className)}>
        <p>Вы выбрали {count} {count < 5 ? count === 1 ? choices[type]['one'] : choices[type]['short'] : choices[type]['many']}</p>
        <div className='multi-actions__btns'>
            {isEdit && <Btn
              className={'multi-actions__btn'}
              size={'sm'}
              color={'dark'}
              onClick={()=> onChange()}
              text={trans('Edit', language)}
            />}
            <Btn
              className={'multi-actions__btn'}
              size={'sm'}
              color={'dark'}
              onClick={()=> onDownload()}
              text={trans('Download', language)}
            />
            {onDownloadTemplate && <Btn
              className={'multi-actions__btn'}
              size={'sm'}
              color={'dark'}
              onClick={()=> onDownloadTemplate()}
              text={trans('Download data', language)}
            />}
            {/*<Btn*/}
            {/*  className={'multi-actions__btn'}*/}
            {/*  size={'sm'}*/}
            {/*  color={'dark'}*/}
            {/*  onClick={()=> null}*/}
            {/*  text='Архивировать'*/}
            {/*/>*/}
            {isDelete &&
            <Btn
              className={'multi-actions__btn'}
              size={'sm'}
              color={'dark'}
              onClick={()=> onDelete()}
              text={trans('Delete', language)}
            />
            }
        </div>
        <Btn
          className={'multi-actions__reset'}
          isIconBtn={true}
          size='sm'
          color='transparent'
          onClick={()=> onReset()}
          icon={{
            Component: CloseIcon,
            width: 16,
            height: 16
          }}
        />
    </div>)
}

export default MultipleActions;