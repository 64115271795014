export const fieldsEcom = {
    "trg_event_dt": "Дата целевого действия",
    "trg_platform": "Платформа целевого действия",
    "trg_url_domain": "Домен целевого взаимодействия",
    "event_dt": "Дата взаимодействия",
    "interaction_type": "Тип взаимодействия",
    "utm_source": "Utm Source",
    "utm_medium": "Utm Medium",
    "utm_campaign": "Utm Campaign",
    "source_nm": "Медиа источник",
    "campaign_nm": "Медиа Кампания",
    "placement_nm": "Медиа Креатив",
    "url_domain": "Домен взаимодействия",
    "ssp": "SSP",
    "platform": "Платформа взаимодейтсивя",

    "ecom_item_id": "Id товара",
    "ecom_item_name": "Наименование товара",
    "ecom_item_category_1": "Категория товара 1",
    "ecom_item_category_2": "Категория товара 2",
    "ecom_item_brand": "Бренд Товара",
}
export const fieldsEcomItems = {
    "ecom_item_id": "Id товара",
    "ecom_item_name": "Наименование товара",
    "ecom_item_category_1": "Категория товара 1",
    "ecom_item_category_2": "Категория товара 2",
    "ecom_item_brand": "Бренд Товара"
}
export const fieldsConditionsEcom = {
    "trg_platform": {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    "trg_url_domain": {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    "interaction_type": {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    "utm_source": {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    "utm_medium": {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    "utm_campaign": {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    "source_nm": {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    "campaign_nm": {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    "placement_nm": {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    "url_domain": {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    "ssp": {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    "platform": {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    //
    // "ecom_item_id": "",
    // "ecom_item_name": "",
    // "ecom_item_category_1": "",
    // "ecom_item_category_2": "",
    // "ecom_item_brand": ""
}
export const fieldsConditionValueEcom = {
    "trg_event_dt": "",
    "trg_platform": "",
    "trg_url_domain": "",
    "event_dt": "",
    "interaction_type": "",
    "utm_source": "",
    "utm_medium": "",
    "utm_campaign": "",
    "source_nm": "",
    "campaign_nm": "",
    "placement_nm": "",
    "url_domain": "",
    "ssp": "",
    "platform": "",

    "ecom_item_id": "",
    "ecom_item_name": "",
    "ecom_item_category_1": "",
    "ecom_item_category_2": "",
    "ecom_item_brand": "",
}

export const fieldsConditionValueTypesEcom = {
    "trg_event_dt": "",
    "trg_platform": "",
    "trg_url_domain": "",
    "event_dt": "",
    "interaction_type": "",
    "utm_source": "",
    "utm_medium": "",
    "utm_campaign": "",
    "source_nm": "",
    "campaign_nm": "",
    "placement_nm": "",
    "url_domain": "",
    "ssp": "",
    "platform": "",

    "ecom_item_id": "",
    "ecom_item_name": "",
    "ecom_item_category_1": "",
    "ecom_item_category_2": "",
    "ecom_item_brand": "",
}

export const metricsEcom = {
    "users": "Уникальных пользователей",
    "add_to_cart_item_quantity": "Добавлено в корзину шт.",
    "purchase_item_quantity": "Продано шт.",
    "purchase_item_amount": "Выручка",
    "AOV": "Средний чек"
}

export const metricsConditionsEcom = {
    "users": {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    "add_to_cart_item_quantity": {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    "purchase_item_quantity": {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    "purchase_item_amount": {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    "AOV": {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    }
}

export const metricsConditionValueEcom = {
    "users": "",
    "add_to_cart_item_quantity": "",
    "purchase_item_quantity": "",
    "purchase_item_amount": "",
    "AOV": ""
}

export const metricsConditionValueTypesEcom = {
    "users": "",
    "add_to_cart_item_quantity": "",
    "purchase_item_quantity": "",
    "purchase_item_amount": "",
    "AOV": ""
}