import {CSSProperties, Dispatch, SetStateAction} from 'react'
import {DefaultObject, IField, ISelectOption} from '../interfaces/common.d'
import {decodeToken} from 'react-jwt'
import {keyToSkip, MAX_NUMBER_FOR_INPUT, PopupType, REG_EMAIL, requiresPresetSources} from '../data'
import {IAccordion} from '../components/ModalForm/ModalForm.d'
import {isValid as isValidDate, parse} from 'date-fns'
import {openPopup} from '../redux/slices/popup'
import {AppDispatch} from '../redux'
import {Source} from '../redux/slices/sources'
import {trans} from "../_locales";
import * as XLSX from 'xlsx';
import {IFilterMediaItem} from "../components/Tables/interfaces";
import {ICustomReport} from "../components/ReportsUpdate/interfaces.d";

export const getNumberWithSpaces = (value: number | string) => {
  if(!isNumber(value))
    return '';
  const parts = removeSpacesInString(value.toString()).split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  if(parts[1])
    parts[1] = +parts[1]>99 ? "99" : parts[1];
  return parts.join(".");
}

export const removeSpacesInString = (value: string) => {
  return value.replace(/\s+/g, '');
}

export const isNumber = (value?: string | number): boolean => {
  if(typeof value === 'number') return true;
  return !!value &&
           !isNaN(Number(removeSpacesInString(value.toString())));
}

export const parseValue = (value: string) =>
  isNumber(value) ? parseInt(value) : value;

export const getCorrectValueForNumber = (value: string, space=false, maxNumber?:number) => {
  if(!isNumber(value)) return null;
  const int = space ? getNumberWithSpaces(value) : parseFloat(value);
  const max = maxNumber ? maxNumber : MAX_NUMBER_FOR_INPUT;
  return +removeSpacesInString(String(int)) > max ? max : int;
}

export const getInitialObject =
  <
    P,
    C extends {
      isRequired: boolean,
      name: string,
      tag: string,
      options: {
        label: string
        value: string
      }[]
    },
    V
  >
  (prev: P, curr: C, value: V) =>
  (((typeof value === 'boolean' && curr.isRequired) || typeof value !== 'boolean') ?
    Object.assign(
      {},
      prev,
      curr && curr.name ? {
        [curr.name]: (curr.tag === 'select' && typeof value !== 'boolean' && curr.options[0]) ?
          curr.options[0].value :  value
      } : {}
    ) : prev);

export const getInitialState = <T extends any[], V>(fields: T, value: V): DefaultObject<V> =>
  fields.reduce((prev, curr) => (
    curr.tag !== 'accordion' ?
      getInitialObject<typeof prev, typeof curr, V>(prev, curr, value) :
      Object.assign({}, prev, curr.content.reduce((itemPrev: any, itemCurr: any) =>
        (getInitialObject<typeof itemPrev, typeof itemCurr, V>(itemPrev, itemCurr, value)), {}))
    ), {}
  );

export const validateUrl = (url: string) => {
  const REG_URL: RegExp = /(http(s)?:\/\/.)(www\.)?([-a-zA-Zа-яА-Я0-9@:%._+~#=]{1,256}\.[a-zа-яА-Я]{2,8}\b([-a-zA-Z0-9а-яА-Я@:%_+.~#?&/=]*)|([а-яА-ЯёЁ]+(\.[а-яА-ЯёЁ]+)+))/g;
  return REG_URL.test(url);
};

export const validateUrlValue = (
  fields: (IField | IAccordion)[],
  form: DefaultObject<string>
): { errorsUrl: DefaultObject<boolean>, hasInvalidUrls: boolean } => {
  let errorsUrl = {};

  if (!fields) return { errorsUrl: {}, hasInvalidUrls: false };

  const fieldsWithUrl = fields.filter((field) => {
    if ('isUrl' in field) return field.isUrl;
  });

  fieldsWithUrl.forEach((field) => {
    if ('name' in field && typeof form[field.name] === 'string') {
      if (field.isRequired || form[field.name]) {
        errorsUrl = Object.assign(
            {},
            errorsUrl,
            {
              [field.name]: !validateUrl(form[field.name])
            }
        )
      }
    }
  });

  const hasInvalidUrls: boolean = !!Object.values(errorsUrl)
    .find((value) => value === true);

  return { errorsUrl, hasInvalidUrls };
}

export const getFormValidate = (
  errors: DefaultObject<boolean>,
) => !Object.values(errors).includes(true);

export const validateOne = (
  key: string,
  value: string,
) => {
  const currentValue =
    key === 'email' ? !REG_EMAIL.test(value as string) :
      ((key === 'password' || key === 'new_password') && (value.length < 6 || value.length > 50)) ?
        true : !value;

  return {[key]: currentValue};
};

export const validateAll = (
  form: DefaultObject<string>,
  errors: DefaultObject<boolean>,
  fields: (IField | IAccordion)[],
  cb: Dispatch<SetStateAction<DefaultObject<boolean>>>
) => {
  let currentErrors = {};
  for (const [key, value] of Object.entries(form)) {
    if (key in errors) {
      currentErrors = {...currentErrors, ...validateOne(key, value)};
    }
    if (key === 'start_date' || key === 'end_date') {
      const dateToForm = parse(value, 'y-MM-dd', new Date());
      if (value && !isValidDate(dateToForm)) {
        currentErrors = {...currentErrors, ...{[key]: true}};
      }
    }
    if(key === 'repeat_password') {
      if(form['password'] !== form['repeat_password'])
        currentErrors = {...currentErrors, ...{['repeat_password']: true}};
    }
    if(key === 'video_orientation') {
      if(form['video_orientation'] !== 'vertical' && form['video_orientation'] !== 'horizontal') {
        form['video_orientation'] = 'vertical';
      }
    }
    if(key === 'privacy-policy' && !value) {
      currentErrors = {...currentErrors, ...{[key]: true}};
    }

    // if(key === 'tags') {
    //   if(!Array.isArray(form['tags']))
    //     currentErrors = {...currentErrors, ...{['tags']: true}};
    // }

    if((key === 'click_params' || key === 'impression_params') && value) {
      let required: Record<string, string> = {};
      if (key === 'impression_params' && requiresPresetSources[`${form['base_source_id']}`]) {
        required = requiresPresetSources[`${form['base_source_id']}`];
      }
      const {errors} = validateQueryParams(value, required);
      if(errors.length > 0) {
        currentErrors = {...currentErrors, ...{[key]: true}};
      }
    }
  }

  const { errorsUrl } =  validateUrlValue(fields as (IField | IAccordion)[], form);

  cb({ ...errors, ...currentErrors, ...errorsUrl })
  return getFormValidate({...errors, ...currentErrors, ...errorsUrl});
};

export const getAuthData = () => {
  const token = localStorage.getItem('token');
  if (token === null)
    return { userId: null, token: null, exp: null, isAuthDataEmpty: true, tokenIsAlive: null };

  const decodedToken = decodeToken(token) as any;
  if(decodedToken === null)
    return { userId: null, token: null, exp: null, isAuthDataEmpty: true, tokenIsAlive: null };

  const userId = decodedToken.sub;
  const exp = decodedToken.exp;
  const dayOfTokenDeath = new Date(0);
  const currentDay = new Date();
  dayOfTokenDeath.setUTCSeconds(exp);
  const tokenIsAlive = dayOfTokenDeath > currentDay;
  return { userId, token, exp, isAuthDataEmpty: false, tokenIsAlive };
};


export const showPopup = (
  dispatch: AppDispatch,
  description?: string,
  type = PopupType.SUCCESS
) => {
  if (description) {
    dispatch(openPopup({
      type,
      description
    }))
  }
};

export const updateSources = (
  fields: (IField | IAccordion)[],
  sources: Source[],
  newSource: Source
) => {
  const sourceSelectData = fields.slice().find((field) =>
    (field.tag === 'select' && field.name === 'source_id'));

  const idx = sourceSelectData ? fields.indexOf(sourceSelectData) : -1;

  const sourcesInRedux = sources.reduce<ISelectOption[]>((acc, curr: Source) =>
    [...acc, { label: curr.name, value: curr.id }], []);

  if (sourceSelectData && sourceSelectData.tag === 'select') {
    sourceSelectData.options = sourcesInRedux ? sourcesInRedux : [];
    if (newSource) sourceSelectData.activeOption = newSource.id;
  }

  return (idx === -1 && !sourceSelectData) ? fields : [
    ...fields.slice(0, idx),
    sourceSelectData,
    ...fields.slice(idx + 1),
  ];
}

export const getContextStyle = (
  parentRect: DOMRect,
  childrenRect: DOMRect,
  space: number,
  setStyle: Dispatch<SetStateAction<CSSProperties>>,
  isFullWidth: boolean,
  isIndentRight: boolean
) => {
  const style: CSSProperties = { position: 'absolute', zIndex: 9999 };
  const getNotNan = (value: number) => isNaN(value) ? 'initial' : value;

  if (isIndentRight) {
    style.right = parentRect.right - parentRect.width;
    style.right = Math.max(space, style.right);
    style.right = Math.min(style.right, document.body.clientWidth - childrenRect.width - space);
    style.right = getNotNan(style.right);
  } else {
    if (isFullWidth) {
      style.width = parentRect.width;
      style.left = parentRect.left;
    } else {
      style.left = (parentRect.left + (parentRect.width / 2)) - (childrenRect.width / 2);
    }

    style.left = Math.max(space, style.left);
    style.left = Math.min(style.left, document.body.clientWidth - childrenRect.width - space);
    style.left = getNotNan(style.left);
  }

  if (parentRect.top < window.innerHeight / 2) {
    style.top = parentRect.top + parentRect.height + space;
    style.top = getNotNan(style.top);
  } else {
    style.bottom = (window.innerHeight - parentRect.top) + space;
    style.bottom = getNotNan(style.bottom);
  }

  setStyle(style);
};

export const currencySign = (value?:'USD' | 'EUR' | 'RUB') => {
  const sign = {
    'RUB': '₽',
    'USD': '$',
    'EUR': '€'
  }
  const defaultCurrencySign = process.env.REACT_APP_DEFAULT_CURRENCY_SIGN || sign['RUB'];
  return value ? sign[`${value}`] : defaultCurrencySign;
}

export const shortenNumber = (num:number, float:boolean = false, separately=false, lang='ru') => {
  if(!num || !isNumber(num)) return separately ? [0, ''] : 0;
  const short = ['', 'thous', 'mln', 'bln', 'trln', 'quadrillion'];
  const thousands = Math.floor( (("" + num.toFixed()).length - 1) / 3 );
  const coef = 1000 ** thousands;
  const floatToFixed = process.env.REACT_APP_FLOAT_TO_FIXED? +process.env.REACT_APP_FLOAT_TO_FIXED : 2;
  let result = num / coef;
  if(!Number.isInteger(result)) {
    result = +( num / coef ).toFixed(float|| num > 1000 ? +floatToFixed : 0);
  }
  if(separately)
    return [result, trans(short[ thousands ], lang)];
  return `${result}${short[ thousands ] ? ' '+trans(short[ thousands ], lang): ''}`;
}

export const formattingValues = (value: string|number, type:string, currency?:'USD' | 'EUR' | 'RUB', separately=false, lang='ru') => {
  if(type === 'integer') {
    return shortenNumber(+String(value).replace(/\s/,'').replace(/,/, ''), false, separately, lang);
  }
  if(type === 'float') {
    return shortenNumber(+String(value).replace(/\s/,'').replace(/,/, ''), true, separately, lang);
  }
  if(type === 'currency') {
    if(separately)
      return [shortenNumber(+String(value).replace(/\s/,'').replace(/,/, ''), true, false, lang), currencySign(currency)];
    return `${shortenNumber(+String(value).replace(/\s/,'').replace(/,/, ''), true, false, lang)} ${currencySign(currency)}`;
  }
  if(type === 'percent') {
    const percentToFixed = process.env.REACT_APP_PERCENT_TO_FIXED || 2;
    if(separately)
      return [( +String(value).replace(/\s/,'').replace(/,/, '') * 100 ).toFixed(+percentToFixed), '%'];
    return ( +String(value).replace(/\s/,'').replace(/,/, '') * 100 ).toFixed(+percentToFixed)+ '%';
  }
  if(type === 'date') {
    return value;
  }
  if(value && isNumber(value)) {
    return value;
  }
  return `${value}`;
}

export const getDefaultValue = (value:any, type?:string) => {
  if(type === 'integer') {
    return value ? value : 0;
  }
  if(type === 'percent') {
    return isNumber(value) ? (+value).toFixed(4) : 0;
  }
  if(type === 'float' || type === 'currency') {
    return isNumber(value) ? (+value).toFixed(2) : 0;
  }
  if(type === 'date' || type === 'string') {
    return value ? value : '';
  }
  if(typeof value === 'number') {
    return value ? value : 0;
  }
  if(typeof value === 'string')
    return value ? value : '';
  return ``;
}

export const getUrlParam = (value:string) => {
  const loc:string = window.location.href;
  const regexp = new RegExp(`${value}=([^&]*)`);
  const searchParams = loc.match(regexp);
  return searchParams ? decodeURIComponent(searchParams[1]) : '';
}

//TODO: testing with selenium
export const checkVisible = function (target:any, reserve=0) {
  const targetPosition = {
    top: window.pageYOffset + target.getBoundingClientRect().top,
    left: window.pageXOffset + target.getBoundingClientRect().left,
    right: window.pageXOffset + target.getBoundingClientRect().right,
    bottom: window.pageYOffset + target.getBoundingClientRect().bottom + reserve
  },
  windowPosition = {
    top: window.pageYOffset,
    left: window.pageXOffset,
    right: window.pageXOffset + document.documentElement.clientWidth,
    bottom: window.pageYOffset + document.documentElement.clientHeight
  };
  return targetPosition.bottom > windowPosition.top && // If the position of the lower part of the element is greater than the position of the upper part of the window, then the element is visible from above
      targetPosition.top < windowPosition.bottom && // If the position of the upper part of the element is less than the position of the lower part of the window, then the element is visible from below
      targetPosition.right > windowPosition.left && // If the position of the right side of the element is greater than the position of the left side of the window, then the element is visible on the left
      targetPosition.left < windowPosition.right && // If the position of the left side of the element is less than the position of the right side of the window, then the element is visible on the right
      targetPosition.bottom < windowPosition.bottom;  //The element is fully visible
};

// export function formatDate(date:Date) {
//   let dd = String(new Date(date).getDate());
//   if (+dd < 10) dd = '0' + dd;
//
//   let mm = String(new Date(date).getMonth() + 1);
//   if (+mm < 10) mm = '0' + mm;
//
//   const yy = new Date(date).getFullYear();
//
//   return yy + '-' + mm + '-' + dd;
// }

export const updateToken = (new_token:string) => {
  const token = localStorage.getItem('token');
  if(token && new_token && token !== new_token) {
    localStorage.setItem('token', new_token)
  }
}

export const daysInMonth = (date:Date) => {
  return 33 - new Date(date.getFullYear(), date.getMonth(), 33).getDate();
}

const getColor = () => {
  const r = Math.floor(Math.random() * (256));
  const g = Math.floor(Math.random() * (256));
  const b = Math.floor(Math.random() * (256));
  return '#' + r.toString(16) + g.toString(16) + b.toString(16);
}
// export const colors = ['#FF004D', '#007BFF', '#2DCE8A', '#FF9900', '#DAEDE7', '#80D8D9', '#F4CAC6', '#4D6569', "#FCDA61", '#ECA0A5', '#C9EB40', '#3E9BFF', "#B9C0FF", '#C06471', getColor(), getColor(), getColor()]
export const colors = ['#007bff', '#B9C0FF', '#80D8D9', '#4D6569', '#FCDA61', '#dd5ad8', '#9c6cf3', '#ff4f8a', '#C06471', '#ffa600', '#F4CAC6', getColor(), getColor(), getColor(), getColor(),getColor(),getColor(),getColor(),getColor(),getColor(),getColor(),getColor(),getColor(),getColor(),getColor(),getColor(),getColor(),getColor(),getColor(),getColor(),getColor(),getColor(),getColor(),getColor(), '#DAEDE7']

export function filterObject(obj:object, callback:any) {
  return Object.fromEntries(Object.entries(obj).
    filter(([key, val]) => callback(val, key)));
}
export const getObjectKeyByValue = (object:any, value:any) => {
  return Object.keys(object).find(key => object[key] === value);
}

export const convertArraysToCsv = (data:Array<Array<any>>, titles:Array<string> = [], countItem:Array<number> = [], fieldTypes:Array<any> = [], aggFunctions:Array<any> = [], currency:'USD' | 'RUB' | 'EUR' = 'USD', metricChoice:string[] = [], aggColIndexesDefault:number[] = [], metricColIndexes:number[] = []) => {
  let excelData = '';

  if(titles.length > 0) {
    titles.forEach((colItem, colIndex) => {
      if (colItem !== keyToSkip && ((metricChoice.length) ? (aggColIndexesDefault.includes(colIndex) || metricColIndexes.includes(colIndex)) : true)) {
        excelData += colItem + ',';
      }
    });
    excelData += "\r\n";
  }
  data.forEach(( rowItem, rowIndex ) => {
      rowItem.forEach((colItem, colIndex) => {
        if(colItem !== keyToSkip && ((metricChoice.length) ? (aggColIndexesDefault.includes(colIndex) || metricColIndexes.includes(colIndex)) : true)) {
          const value = (fieldTypes[colIndex] === 'percent' || aggFunctions[colIndex] === 'avg') && countItem && countItem[rowIndex] && aggFunctions[colIndex] !== 'calc' ?
            +colItem / +countItem[rowIndex] : colItem;
          excelData += `${value}` + ',';
        }
      })
      excelData += "\r\n";
  });
  return excelData;
}


export const prepareArraysToXlsx = (data:Array<Array<any>>, titles:Array<string> = [], countItem:Array<number> = [], fieldTypes:Array<any> = [], aggFunctions:Array<any> = [], currency:'USD' | 'RUB' | 'EUR' = 'USD', metricChoice:string[] = [], aggColIndexesDefault:number[] = [], metricColIndexes:number[] = []) => {
  const excelData:Array<Array<any>> = [];

  if(titles.length > 0) {
    const temp:string[] = [];
    titles.forEach((colItem, colIndex) => {
      if (colItem !== keyToSkip && ((metricChoice.length) ? (aggColIndexesDefault.includes(colIndex) || metricColIndexes.includes(colIndex)) : true)) {
        temp.push(colItem);
      }
    });

    excelData.push(temp);
  }
  data.forEach(( rowItem, rowIndex ) => {
      const temp:Array<any> = [];
      rowItem.forEach((colItem, colIndex) => {
        if(colItem !== keyToSkip && ((metricChoice.length) ? (aggColIndexesDefault.includes(colIndex) || metricColIndexes.includes(colIndex)) : true)) {
          const value = (fieldTypes[colIndex] === 'percent' || aggFunctions[colIndex] === 'avg') && countItem && countItem[rowIndex] && aggFunctions[colIndex] !== 'calc' ?
            +colItem / +countItem[rowIndex] : colItem;
          temp.push(value);
        }
      })
      excelData.push(temp);
  });
  return excelData;
}


function s2ab(s:any) {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
  return buf;
}
export const downloadCsv = function (data:any, type:'csv'|'xlsx' = 'csv', name='download') {
    if(data === '') return;
    if(type === 'csv') {
      const blob = new Blob([data], { type: `text/${type}` });
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')

      a.setAttribute('href', url)
      a.setAttribute('download', `${name.split(' ').join('_')}.${type}`);
      a.click()
    }
    if(type === 'xlsx') {
      const url = window.URL.createObjectURL(data);
      const a = document.createElement("a");
      a.setAttribute("href", url);
      a.setAttribute("download", `${name.split(' ').join('_')}.${type}`);
      document.body.appendChild(a);
      a.click();
    }
}


export const generateAndDownloadXlsx = (data: Array<Array<any>>, name='data') => {
  const worksheet = XLSX.utils.aoa_to_sheet([...data]);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  const xlsxBuffer = XLSX.write(workbook, { type: 'array', bookType: 'xlsx' });



  const blob = new Blob([xlsxBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${name}.xlsx`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};


export const getTimezoneBrowser = () => {
  //Не забыть включить тесты
  // const timezone = new Date().getTimezoneOffset()/-60;
  // if(timezone > 0) return `Etc/GMT+${timezone}`
  // return `Etc/GMT${timezone}`
  return 'Etc/GMT+3'
}
export const getLangBrowser = () => {
  //Не забыть включить тесты
  // if(navigator.language) return navigator.language.substring(0,2);
  return 'ru';
}

export const removeObjectKeys = (obj:object, keysToRemove:Array<string|number>, cantRemove: Array<string|number> = []) => {
  const result:any = {...obj};
  keysToRemove.forEach(key => {
    if(!cantRemove.includes(key))
      delete result[key];
  });
  return result;
}

export const limitStr = (str:string, n:number, symb?:string) => {
    if (!n && !symb) return str;
    symb = symb || '';
    return str.substr(0, n - symb.length) + symb;
}

export const translit = (word:string) => {
  const converter:any = {
    'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd',
    'е': 'e', 'ё': 'e', 'ж': 'zh', 'з': 'z', 'и': 'i',
    'й': 'y', 'к': 'k', 'л': 'l', 'м': 'm', 'н': 'n',
    'о': 'o', 'п': 'p', 'р': 'r', 'с': 's', 'т': 't',
    'у': 'u', 'ф': 'f', 'х': 'h', 'ц': 'c', 'ч': 'ch',
    'ш': 'sh', 'щ': 'sch', 'ь': '', 'ы': 'y', 'ъ': '',
    'э': 'e', 'ю': 'yu', 'я': 'ya'
  };

  word = word.toLowerCase();

  let answer = '';
  for (let i = 0; i < word.length; ++i) {
    if (converter[word[i]] == undefined) {
      answer += word[i];
    } else {
      answer += converter[word[i]];
    }
  }

  answer = answer.replace(/[^-0-9a-z]/g, '-');
  answer = answer.replace(/[-]+/g, '-');
  answer = answer.replace(/^-|-$/g, '');
  return answer;
}

export class QueueServer {
  private queue: any[];
  private readonly maxWeight: number;
  private currentWeight: number;
  constructor(maxWeight:number = 30) {
    this.maxWeight = maxWeight;
    this.queue = [];
    this.currentWeight = 0;
  }

  enqueue(func:() => Promise<any>, weight:number) {
    this.queue.push({ func, weight });
    this.processQueue();
  }

  processQueue() {
    if (this.currentWeight >= this.maxWeight || this.queue.length === 0) {
      return;
    }

    const nextFunc = this.queue[0];

    if (nextFunc) {
      const index = this.queue.indexOf(nextFunc);
      const { func, weight } = this.queue.splice(index, 1)[0];
      this.currentWeight += weight;
      func().then(()=> {
        this.currentWeight -= weight;
        this.processQueue();
      })
    }
  }
}

export const findMaxValue = (list:Array<number>) => {
  let max:number = list[0] ? list[0] : 0;
  for (let i = 1; i < list.length; i++) {
    if (list[i] > max) {
      max = list[i];
    }
  }
  return max;
}

export const getCountDays = (start: string, end: string): number => {
  const parsedStart = start.split('-');
  const parsedEnd = end.split('-');
  if(parsedStart.length !== 3 || parsedEnd.length !== 3)
    return 0;
  const startDate = new Date(+parsedStart[0], +parsedStart[1]-1, +parsedStart[2]);
  const endDate = new Date(+parsedEnd[0], +parsedEnd[1]-1, +parsedEnd[2]);

  const millisecondsPerDay = 24 * 60 * 60 * 1000;

  const diffInMilliseconds = Math.abs(endDate.getTime() - startDate.getTime());

  return Math.round(diffInMilliseconds / millisecondsPerDay) + 1;
}

export const checkInPeriod = (date: string, start: string, end: string): boolean => {
  const parsedStart = start.split('-');
  const parsedEnd = end.split('-');
  const parsedCurrent = date.split('-');

  if(parsedStart.length !== 3 || parsedEnd.length !== 3 || parsedCurrent.length !== 3)
    return false;
  if(parsedStart[0].length !== 4 || parsedStart[1].length !== 2 || parsedStart[2].length !== 2)
    return false;
  if(parsedEnd[0].length !== 4 || parsedEnd[1].length !== 2 || parsedEnd[2].length !== 2)
    return false;
  if(parsedCurrent[0].length !== 4 || parsedCurrent[1].length !== 2 || parsedCurrent[2].length !== 2)
    return false;
  const startDate = new Date(+parsedStart[0], +parsedStart[1]-1, +parsedStart[2]);
  const endDate = new Date(+parsedEnd[0], +parsedEnd[1]-1, +parsedEnd[2]);
  const currentDate = new Date(+parsedCurrent[0], +parsedCurrent[1]-1, +parsedCurrent[2]);

  return currentDate >= startDate && currentDate <= endDate;
}


export function calculatePercentage(number:number, total:number) {
  if(number === 0 && total === 0)
    return 0;
  if(total === 0)
    return number * 100;
  const result = (number / total) * 100;
  if (Number.isInteger(result)) {
    return result;
  }
  return result.toFixed(2);
}

export const getFromObject = (obj:any, keys:Array<string>) => {
  const result:any = {};
  for(const key in obj) {
    if(keys.includes(key)) {
      result[key] = obj[key];
    }
  }
  return result;
}

export function getInterval(today:Date, period: ICustomReport["period"]): { interval_from: string, interval_to: string }|undefined {
  if (period === 'choice') return;
  if (period === 'today') {
    return {
      interval_from: formatDate(new Date()),
      interval_to: formatDate(new Date())
    };
  }
  if (period === 'yesterday') {
    return {
      interval_from: formatDate(today),
      interval_to: formatDate(today)
    };
  }
  if (period === 'year') {
    const year = today.getFullYear();
    return {
      interval_from: formatDate(new Date(year, 0)),
      interval_to: formatDate(today)
    };
  }
  if (period === 'month') {
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    return {
      interval_from: formatDate(startOfMonth),
      interval_to: formatDate(today)
    };
  }

  if (period === 'previous_month') {
    const previousMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const lastDayPreviousMonth = new Date(today.getFullYear(), today.getMonth(), 0);
    return {
      interval_from: formatDate(previousMonth),
      interval_to: formatDate(lastDayPreviousMonth)
    };
  }

  if (period === 'week') {
    const weekStart = getWeekStart(today);
    return {
      interval_from: formatDate(weekStart),
      interval_to: formatDate(today)
    };
  }

  if (period === 'previous_week') {
    const previousWeekStart = getWeekStart(new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7));
    const previousWeekEnd = new Date(previousWeekStart.getFullYear(), previousWeekStart.getMonth(), previousWeekStart.getDate() + 6);
    return {
      interval_from: formatDate(previousWeekStart),
      interval_to: formatDate(previousWeekEnd)
    };
  }

  return undefined;
}

export const formatDate = (date: Date): string => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export const isDate = (date:string): boolean => {
  const regex = /^\d{4}-\d{2}-\d{2}$/;
  if(date)
    return regex.test(date);
  return false;
}

export const getWeekStart = (date: Date): Date => {
  const m = new Date(date);
  if(date.getDay()){m.setDate(date.getDate() + 1 - date.getDay())} else {m.setDate(date.getDate() - 6)}
  return m;
}

export const getYesterday = (date: Date): Date => {
  const m = new Date(date);
  m.setDate(m.getDate() - 1);
  return m;
}

interface CustomFilterItem {
  id?: number,
  name: string;
  condition: string;
  value: string | number;
}
export function parseFilter(data: string, sepPairs:string='&', sepValues:string = ','): IFilterMediaItem[] {
  const items: IFilterMediaItem[] = [];

  const pairs = data.split(sepPairs);
  pairs.map((pair, index) => {
    const keyValuePairs = pair.split(";");
    const item: IFilterMediaItem = {
      name: "",
      condition: "",
      value: []
    };

    for (const keyValuePair of keyValuePairs) {
      const [key, value] = keyValuePair.split("=");
      if (key === "name") {
        item.name = value;
      } else if (key === "condition") {
        item.condition = value;
      } else if (key === "value") {
        const values = value.split(sepValues);
        for (const v of values) {
          if (!isNaN(Number(v))) {
            item.value.push(Number(v));
          } else {
            item.value.push(v);
          }
        }
      }
    }
    if (item.name && item.condition && item.value.length > 0) {
      item.id = Math.floor(Math.random() * 10000);
      items.push(item);
    }
  })

  return items;
}

export function stringifyFilter(data: IFilterMediaItem[], sepPairs:string='&', sepValue:string = ','): string {
  const filterString: string[] = [];

  for (const item of data) {
    const valuesString:IFilterMediaItem['value'] = [];
    for (const value of item.value) {
      if (isNumber(value) || value)
        valuesString.push(value);
    }
    if(item.name && item.condition && valuesString.length > 0) {
      filterString.push(`name=${item.name};condition=${item.condition};value=${valuesString.join(sepValue)}`);
    }
  }

  return filterString.join(sepPairs);
}


export function parseCustomFilterItem(data: string): CustomFilterItem[] {
  const items: CustomFilterItem[] = [];

  const pairs = data.split("&");
  pairs.map((pair, index) => {
    const keyValuePairs = pair.split(";");
    const item: CustomFilterItem = {
      name: "",
      condition: "",
      value: ''
    };

    for (const keyValuePair of keyValuePairs) {
      const [key, value] = keyValuePair.split("=");
      if (key === "name") {
        item.name = value;
      } else if (key === "condition") {
        item.condition = value;
      } else if (key === "value") {
        if (!isNaN(Number(value))) {
          item.value = Number(value);
        } else {
          item.value = value;
        }
      }
    }
    if (item.name && item.condition && item.value) {
      item.id = Math.floor(Math.random() * 10000);
      items.push(item);
    }
  })

  return items;
}


export function parseCustomFilter(data: string): IFilterMediaItem[] {
  const items: IFilterMediaItem[] = [];

  const pairs = data.split("&");
  pairs.map((pair, index) => {
    const keyValuePairs = pair.split(";");
    const item: IFilterMediaItem = {
      name: "",
      condition: "",
      value: []
    };

    for (const keyValuePair of keyValuePairs) {
      const [key, value] = keyValuePair.split("=");
      if (key === "name") {
        item.name = value;
      } else if (key === "condition") {
        item.condition = value;
      } else if (key === "value") {
        const values = value.split('|');
        for (const v of values) {
          if (!isNaN(Number(v))) {
            item.value.push(Number(v));
          } else {
            item.value.push(v);
          }
        }
      }
    }
    if (item.name && item.condition && item.value) {
      item.id = Math.floor(Math.random() * 10000);
      items.push(item);
    }
  })

  return items;
}


interface SortItem {
  id?: number,
  name: string;
  value: string;
}
export function parseSort(data: string): SortItem[] {
  const items: SortItem[] = [];

  const pairs = data.split("&");
  pairs.map((pair, index) => {
    const keyValuePairs = pair.split(";");
    const item: SortItem = {
      name: "",
      value: ''
    };

    for (const keyValuePair of keyValuePairs) {
      const [key, value] = keyValuePair.split("=");
      if (key === "name") {
        item.name = value;
      } else if (key === "value") {
        item.value = value;
      }
    }
    if (item.name && (item.value === 'descending' || item.value === 'ascending')) {
      item.id = Math.floor(Math.random() * 10000);
      items.push(item);
    }
  })

  return items;
}

export const isValueObjectInArray = (arr:Array<any>, key:string|number, value:any) => {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i][key] === value) {
      return true;
    }
  }
  return false;
}

export const modifyString = (str:string):string => {
  if (str.length <= 8) {
    return str;
  }

  const firstPart = str.substr(0, 4);
  const lastPart = str.substr(-4);
  return firstPart + "***********" + lastPart;
}

export const sendSdk = (type:string, data:object) => {
  if (window.location.hostname.includes('localhost')) {
    // if(typeof _targetadsTag === 'function') _targetadsTag(type, data);
    return;
  }
  if (window.location.hostname.includes('dev')) {
    return;
  }
  if (window.location.hostname.includes('app')) {
    if(typeof _targetadsTag === 'function') _targetadsTag(type, data); 
    return;
  }
}

export const copyText = (value:string) => {
  return navigator.clipboard.writeText(value).then(r => document.execCommand('Copy')).then(()=>console.log('value copied'))
}

export const linkTo = (element:HTMLElement) => {
  element.scrollIntoView({
    behavior: 'smooth',
    block: 'start'
  })
}

export const validateQueryParams = (query: string, required?:Record<string, string>): { query: string, errors: string[] } => {
    const paramsDict: Record<string, string> = {};
    const validatedParams: string[] = [];
    const errors: string[] = [];

    const pairs: string[] = query.split('&');

    for (const pair of pairs) {
        const [key, value] = pair.split('=');
        if (key && value) {
            if(required && required[key]) {
              paramsDict[key] = required[key];
            }
            let temp = value;
            if(required && required[key]) {
              if(value !== required[key]) {
                errors.push(`Required key '${key}' is incorrect. Value should by equal is '${required[key]}'`);
              }
              temp = required[key];
            }
            if (key in paramsDict && !(required && required[key])) {
                errors.push(`Duplicated key '${key}' found. Previous value '${paramsDict[key]}' will be overridden with '${temp}'.`);
            }
            paramsDict[key] = temp;
        } else {
            errors.push(`Empty value for key '${key}'. This pair will be ignored.`);
        }
    }

    if(required) {
      Object.keys(required).map(key => {
        if(!(key in paramsDict)) {
          errors.push(`Key '${key}' required for this query`);
          paramsDict[key] = required[key];
        }
      })
    }

    for (const [key, value] of Object.entries(paramsDict)) {
        validatedParams.push(`${key.trim()}=${value.trim()}`);
    }

    return {
        query: validatedParams.join('&'),
        errors: errors
    };
}


export function filterAndSortDataByMaxValues(data: { [key: string]: { [key: string]: number|null } }, keys: string[]): string[] {
  const filteredData: { [key: string]: number } = {};
  Object.keys(data).forEach(key => {
    const values: number[] = keys.map(label => data[key][label] || 0);
    filteredData[key] = Math.max(...values);
  });

  return Object.keys(filteredData).sort((a, b) => filteredData[b] - filteredData[a]);
}

export function getValueByNestedKey<T>(obj: T, key: string): any {
  const keys = key.split('.');
  let value: any = obj;

  for (const nestedKey of keys) {
    if (value && typeof value === 'object' && nestedKey in value) {
      value = value[nestedKey];
    } else {
      return undefined;
    }
  }

  return value;
}


/**
 * Рассчитывает количество месяцев между двумя датами, включая начальный и конечный месяцы.
 *
 * @param startDate Начальная дата в формате 'ГГГГ-ММ-ДД'.
 * @param endDate Конечная дата в формате 'ГГГГ-ММ-ДД'.
 * @returns Количество месяцев между датами.
 */
export const calculateMonthsBetweenDates = (startDate: string, endDate: string): number => {
  const startDateParts = startDate.split('-');
  const endDateParts = endDate.split('-');

  const startYear = parseInt(startDateParts[0]);
  const startMonth = parseInt(startDateParts[1]);

  const endYear = parseInt(endDateParts[0]);
  const endMonth = parseInt(endDateParts[1]);

  return (endYear - startYear) * 12 + (endMonth - startMonth) + 1;
};

export const isValidDateFormat = (date:string) => {
  const reg_date = new RegExp('^\\d{4}\\-(0[1-9]|1[012])\\-(0[1-9]|[12][0-9]|3[01])$');
  return reg_date.test(date);
}

export const removeFromObjectByKey = (object:DefaultObject<any>, key:string)=> {
  const temp = {...object};
  delete temp[key];
  return temp;
}

export const onChangeValueCheckbox = (response: any) => {
  const temp: Array<any> = [];
  const total: Array<any> = [];
  for (const key in response) {
    if (response[key] && response[key].checked) {
      temp.push(response[key].name);
      total.push(key);
    }
  }
  const result = temp.join(', ');

  return {
    title: result,
    activeList: total
  }
}

export const onRemoveFormItem = ({
    id, current, set, cb
}:{
    id:number, current:any, set:any, cb?: any
}) => {
    try {
        const temp = current.filter((item: any) => item.id !== id);
        set(temp);
        cb && cb();
    } catch (e) {
        console.log('Error', e);
    }
}

export const onHandlerFormChanges = ({
    changes,
    id,
    set,
    current,
    onRemove=onRemoveFormItem,
    type='',
    setUpdateValueFlag
}:{
  changes:any,
  id:number,
  set:any,
  current:any,
  onRemove?:any,
  type?:string,
  setUpdateValueFlag?: any
}) => {
  try {
    for (const key in changes) {
      if (key === 'name') {
        if (changes[key]) {
          set((prevState: any) =>
              prevState.map((item: any) =>
                  item.id === id
                      ? {...item, name: changes[key]}
                      : item
              )
          );
        } else {
          onRemove(id, current, set);
        }
      }
      if (key === 'condition') {
        set((prevState: any) =>
            prevState.map((item: any) => {
              if (item.id === id) {
                if (changes[key])
                  return {...item, condition: changes[key]};
                delete item.condition;
                return item;
              }
              return item;
            })
        );
      }
      if (key === 'value') {
        set((prevState: any) =>
            prevState.map((item: any) => {
              try {
                if (item.id === id) {
                  if ((typeof changes[key] === 'string' && changes[key]) || (typeof changes[key] === 'object' && changes[key].length > 0)) {
                    setUpdateValueFlag && setUpdateValueFlag(true);
                    return {...item, value: changes[key], type: type};
                  }
                  delete item.value;
                  return item;
                }
                return item;
              } catch (e) {
                console.log('Error', e);
                return item;
              }
            })
        )
      }
    }
  } catch (e) {
    console.log('Error', e);
  }
}

export const getScreenResolution = (): string => {
  const width = window.screen.width;
  const height = window.screen.height;
  return `${width}x${height}`;
}


export const isContainsAnyFromArrayInOtherArray = <T>(array1: T[], array2: T[]): boolean => {
    return array1.some(item => array2.includes(item));
}

/**
 * Возвращает массив уникальных строк из исходного массива
 * @param arr - исходный массив строк
 * @returns массив уникальных строк
 */
export const getUniqueStrings = (arr: string[]): string[] => {
  if(Array.isArray(arr)) {
    return Array.from(new Set(arr));
  }
  return [];
}

/**
 * Возвращает объект с разрешенными ключами
 * @param obj - Первоначальный объект
 * @param allowedKeys - Допустимые ключи
 * @returns объект с разрешенными ключами
 */
export const removeInvalidNumberKeys = (
  obj: DefaultObject<any>,
  allowedKeys: number[]
): DefaultObject<any> => {
  const result: DefaultObject<any> = {};

  for (const key in obj) {
    if (allowedKeys.includes(Number(key))) {
      result[key] = obj[key];
    }
  }

  return result;
}
