import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'
import {
  getAgeChart,
  getCircleChart,
  getLinearChart,
  getCampaigns,
  getDashboards,
  getProjectGeneral,
  getProjectAttributions,
  updateProjectAttributions,
  updateProjectEvents,
  downloadProjectIntegration,
  addProjectIntegration,
  downloadIntegrations,
  authorizeYandexDirect,
  selectClientForAuthYandexDirect,
  authorizeHybrid,
  selectClientForHybrid,
  authorizeGetIntent,
  updateIntegrationName
} from '../../actions/project'
import {IIntegrations, IProject, IProjectAttributions} from "../../interfaces/common.d";
import {isNumber} from "../../utils";

// interface StepDone {
//   step: false
// }
interface ICurrentChartAge {
  quantile_025: number
  quantile_050: number
  avg_impression: number
  quantile_075: number
  quantile_090: number
}
interface ProjectState {
  data: []
  general: IProject | null
  age?: ICurrentChartAge[]
  circle?: []
  linear?: []
  linearCollect?: []
  campaigns?: []
  attributions: IProjectAttributions | null
  integrations?: any
  loaded: boolean
  isLoading: boolean
  error: string | null
  listIntegrations: any
  current: number | null
  prev: number | null
}

const initialState: ProjectState = {
  data: [],
  general: null,
  age: [],
  linear: [],
  linearCollect: [],
  circle: [],
  campaigns: [],
  attributions: null,
  integrations: null,
  listIntegrations: null,
  loaded: false,
  isLoading: false,
  error: null,
  current: null,
  prev: null
}

export const projectSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setProject: (state, action) => {
      state.data = action.payload;
    },
    clearProject: (state) => {
      state.data = [];
    },
    setProjectLoaded: (state, action) => {
      state.loaded = action.payload
    },
    removeError: (state) => {
      state.error = null;
    },
    clearProjectGeneral: (state) => {
      state.general = null
    },
    clearProjectAttributions: (state) => {
      state.attributions = null
    },
    setCurrentProject: (state, action) => {
      if(isNumber(action.payload)) {
        if(state.current && state.current !== action.payload && state.prev !== state.current) {
          state.prev = state.current;
        }
        state.current = action.payload;
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getProjectGeneral.fulfilled, (state, action: PayloadAction<IProject>) => {
      state.general = action.payload;
    });
    builder.addCase(
      getAgeChart.fulfilled,
      (state, action: PayloadAction<ICurrentChartAge[]>) => {
      state.age = action.payload ? action.payload : [];
      state.loaded = true;
      state.isLoading = false;
    });
    builder.addCase(
      getAgeChart.pending,
      (state) => {
      state.isLoading = true;
      state.loaded = false;
    });
    builder.addCase(
      getAgeChart.rejected,
      (state,  action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload
    });
    builder.addCase(
      getCircleChart.fulfilled,
      (state, action: PayloadAction<any>) => {
      state.circle = action.payload ? action.payload : [];
      state.loaded = true;
      state.isLoading = false;
    });
    builder.addCase(
      getCircleChart.pending,
      (state) => {
      state.isLoading = true;
      state.loaded = false;
    });
    builder.addCase(
      getCircleChart.rejected,
      (state,  action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload
    });
    builder.addCase(
      getLinearChart.fulfilled,
      (state, action: PayloadAction<any>) => {
      state.linear = action.payload ? action.payload : [];
      state.loaded = true;
      state.isLoading = false;
    });
    builder.addCase(
      getLinearChart.pending,
      (state) => {
      state.isLoading = true;
      state.loaded = false;
    });
    builder.addCase(
      getLinearChart.rejected,
      (state,  action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload
    });
    builder.addCase(
      getCampaigns.fulfilled,
      (state, action: PayloadAction<any>) => {
      state.campaigns = action.payload ? action.payload : [];
      state.loaded = true;
      state.isLoading = false;
    });
    builder.addCase(
      getCampaigns.pending,
      (state) => {
      state.isLoading = true;
      state.loaded = false;
    });
    builder.addCase(
      getCampaigns.rejected,
      (state,  action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload
    });
    builder.addCase(
      getDashboards.fulfilled,
      (state, action: PayloadAction<any>) => {
      state.linearCollect = action.payload ? action.payload : [];
      state.loaded = true;
      state.isLoading = false;
    });
    builder.addCase(
      getDashboards.pending,
      (state) => {
      state.isLoading = true;
      state.loaded = false;
    });
    builder.addCase(
      getDashboards.rejected,
      (state,  action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload
    });
    builder.addCase(getProjectAttributions.fulfilled, (state, action: PayloadAction<IProjectAttributions>) => {
      state.attributions = action.payload;
    });
    builder.addCase(
      updateProjectAttributions.fulfilled,
      (
        state
      ) => {
        state.isLoading = false;
      });
    builder.addCase(
      updateProjectAttributions.pending,
      (state) => {
        state.isLoading = true;
      });
    builder.addCase(
      updateProjectAttributions.rejected,
      (state,  action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload
      });
    builder.addCase(
      updateProjectEvents.fulfilled,
      (
        state
      ) => {
        state.isLoading = false;
      });
    builder.addCase(
      updateProjectEvents.pending,
      (state) => {
        state.isLoading = true;
      });
    builder.addCase(
      updateProjectEvents.rejected,
      (state,  action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload
      });
    builder.addCase(downloadProjectIntegration.fulfilled, (state, action: PayloadAction<any>) => {
      state.integrations = action.payload;
    });
    builder.addCase(
      addProjectIntegration.fulfilled,
      (
        state
      ) => {
        state.isLoading = false;
      });
    builder.addCase(
      addProjectIntegration.pending,
      (state) => {
        state.isLoading = true;
      });
    builder.addCase(
      addProjectIntegration.rejected,
      (state,  action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload
      });
    builder.addCase(downloadIntegrations.fulfilled, (state, action: PayloadAction<IIntegrations>) => {
      state.listIntegrations = action.payload;
    });
    builder.addCase(
      downloadIntegrations.rejected,
      (state,  action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload
      });
    builder.addCase(
      authorizeYandexDirect.rejected,
      (state,  action: PayloadAction<any>) => {
        state.error = action.payload
      });
    builder.addCase(
      authorizeHybrid.rejected,
      (state,  action: PayloadAction<any>) => {
        state.error = action.payload
      });
    builder.addCase(
      selectClientForHybrid.rejected,
      (state,  action: PayloadAction<any>) => {
        state.error = action.payload
      });
    builder.addCase(
      authorizeGetIntent.rejected,
      (state,  action: PayloadAction<any>) => {
        state.error = action.payload
      });
    builder.addCase(
      updateIntegrationName.rejected,
      (state,  action: PayloadAction<any>) => {
        state.error = action.payload
      });
    builder.addCase(
      selectClientForAuthYandexDirect.rejected,
      (state,  action: PayloadAction<any>) => {
        state.error = action.payload
      });
  }
})

export const { setProject,
  removeError,
  clearProject,
  setProjectLoaded,
  clearProjectGeneral,
  clearProjectAttributions,
  setCurrentProject
} = projectSlice.actions

export const projectData = createSelector(
  (state: RootState) => state.project,
  (project) => project.data
)

export const errorValue = createSelector(
  (state: RootState) => state.project,
  (project) => project.error
)

export const projectGeneral = createSelector(
  (state: RootState) => state.project,
  (project) => project.general
)

export const projectAge= createSelector(
  (state: RootState) => state.project,
  (project) => project.age
)

export const projectCircle= createSelector(
  (state: RootState) => state.project,
  (project) => project.circle
)

export const projectLinear= createSelector(
  (state: RootState) => state.project,
  (project) => project.linear
)

export const projectLinearCollect= createSelector(
  (state: RootState) => state.project,
  (project) => project.linearCollect
)

export const projectCampaigns= createSelector(
  (state: RootState) => state.project,
  (project) => project.campaigns
)

export const projectAttributions = createSelector(
  (state: RootState) => state.project,
  (project) => project.attributions
)

export const projectIntegrations = createSelector(
  (state: RootState) => state.project,
  (project) => project.integrations
)

export const listIntegrations = createSelector(
  (state: RootState) => state.project,
  (project) => project.listIntegrations
)

export const getPrevProject = createSelector(
  (state: RootState) => state.project,
  (project) => project.prev
)

export default projectSlice.reducer

