import React, {ChangeEvent, FC, useEffect, useRef, useState} from "react";

import cls from './style.module.scss';
import {getProjectGeneral, updateProjectEvents} from "../../actions/project";
import {Link, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {Toggle} from "../../ui";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {currentUserLang, userId} from "../../redux/slices/user";
import {eventsData, ICurrentEvents} from "../../redux/slices/events";
import {getEvents, updateEvent} from "../../actions/events";
import Tooltip from "../Tooltip";
import DocsCounter from "../Modals/DocsCounter";
import Btn from "../../ui/Btn";
import {trans} from "../../_locales";
import {getNumberWithSpaces, removeSpacesInString, sendSdk} from "../../utils";
import {useOnClickOutside} from "../../helpers";
import CodeFormatter from "../CodeFormatter";
import Code from "../Code";
import {DefaultObject} from "../../interfaces/common.d";

export type IEventsCreateType = 'web_event' | 'purchase' | 'add_to_cart' | 'offline_event' | 'offline_call' | 'in_app';

interface EventsCreateProps {
  className?: string;
  edit?: boolean;
  dataEdit?: IData
  type?: IEventsCreateType
}

interface IData {
  id?: number;
  event_target_name?: string;
  event_target_price?: number
  calculate_attribution_models?: boolean
  event_type?: string
  event_category?: string
  event_name: string
  event_value?: number
  count?:number;
  created_at?:string;
  sys_type?:string
}

const EventsCreate: FC<EventsCreateProps> = ({
  className,
  type='web_event',
  edit
}) => {
  const {projectId} = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const reduxEvents = useAppSelector(eventsData);
  const language = useAppSelector(currentUserLang);
  const reduxUserId = useAppSelector(userId);
  const { eventsId } = useParams();

  const [targetName, setTargetName] = useState('');
  const [targetPrice, setTargetPrice] = useState(0);
  const [calculateModels, setCalculateModels] = useState(false);
  const [eventType, setEventType] = useState<string>('');
  const [eventCategory, setEventCategory] = useState('');
  const [eventName, setEventName] = useState('');
  const [eventValue, setEventValue] = useState<number | string>(0);
  const [data, setData] = useState<IData>({
    event_type : eventType,
    event_category : eventCategory,
    event_name : eventName,
    sys_type: 'Web'
  });
  const [sdk, setSdk] = useState<IData>({
    event_type : eventType,
    event_category : eventCategory,
    event_name : eventName
  });
  useEffect(() => {
    dispatch(getEvents({projectId}));
  }, [dispatch, projectId]);
  useEffect(()=> {
    if(type === 'web_event') {
      changeEventName('');
    }
    if(type === 'purchase') {
      changeEventName('purchase');
    }
    if(type === 'add_to_cart') {
      changeEventName('add_to_cart');
    }
    if(type === 'offline_event') {
      changeEventName('');
    }
    if(type === 'offline_call') {
      changeEventName('');
    }
    if(type === 'in_app') {
      changeEventName('');
    }
  }, [type])
  useEffect(()=>{
    const item = reduxEvents.filter((item)=> item.id === Number(eventsId))[0];
    if(item) {
      setTargetName(item.event_target_name ? item.event_target_name : '');
      setTargetPrice(item.event_target_price ? item.event_target_price : -1);
      setCalculateModels(item.calculate_attribution_models ? item.calculate_attribution_models : false);
      setEventType(item ? item.event_type : '');
      setEventCategory(item ? item.event_category : '');
      setEventName(item ? item.event_name : '');
      setEventValue(item.event_value ? item.event_value : -1);
      setData(item);
      const temp:IData = {
        event_type: '',
        event_category: '',
        event_name: '',
      };
      // temp.event_target_name = item.event_target_name;
      temp.event_type = item.event_type;
      temp.event_category = item.event_category;
      temp.event_name = item.event_name;
      if(item.event_value) temp.event_value = item.event_value;
      // if(item.event_target_price) temp.event_target_price = item.event_target_price;
      setSdk(temp);
    }
  },[reduxEvents])

  const [step, setStep] = useState(1);
  const [exitCounter, setExitCounter] = useState<boolean>(false);
  const changeCalculateModels = () => {
    const result = !calculateModels;
    setCalculateModels(result);
    setData({...data, calculate_attribution_models: result});
    if(targetName && eventName) setExitCounter(true);
  }

  const [errors, setErrors] = useState<DefaultObject<string>>({});
  const validateAll = (data: IData) => {
    const temp = JSON.parse(JSON.stringify(errors));
    let isError = false;
    temp['event_target_name'] = '';
    temp['general'] = '';
    reduxEvents.map((item)=> {
      if(item.id !== Number(eventsId)) {
        if(item.event_target_name && item.event_target_name.toLowerCase() === data.event_target_name?.toLowerCase()) {
          temp['event_target_name'] = trans('This name already exist', language);
          isError = true;
        }
        if(item.event_name === data.event_name && item.event_category === data.event_category && item.event_type === data.event_type && item.sys_type === data.sys_type) {
          temp['general'] = trans('Event with this params already exist', language);
          isError = true;
        }
      }
    })
    setErrors(temp);
    return !isError;
  }

  const changeTargetName = (value:string)=> {
    if(validateSQLString(value)) {
      setTargetName(value);
      setData({...data, event_target_name: value});
      // setSdk( {...sdk, event_target_name: value});
      value && eventName ? setExitCounter(true) : setExitCounter(false);
    }
  }
  const changeTargetPrice = (value:string) => {
    if(!isNaN(Number(value))) {
      setTargetPrice(Number(value));
      setData({...data, event_target_price: Number(value)});
      // setSdk({...sdk, event_target_price: Number(value)});
      if(targetName && eventName) setExitCounter(true);
      return;
    }
    setTargetPrice(0);
  }
  const changeEventValue = (value:string) => {
    if(!isNaN(Number(removeSpacesInString(value)))) {
      const result = getNumberWithSpaces(value);
      setEventValue(result);
      setData( {...data, event_value: Number(removeSpacesInString(result))});
      setSdk( {...sdk, event_value: Number(removeSpacesInString(result))});
      if(targetName && eventName) setExitCounter(true);
      return;
    }
    setEventValue(0);
  }

  const changeEventCategory = (value:string)=> {
    if(validateSQLString(value, true)) {
      setEventCategory(value);
      setData({...data, event_category: value});
      setSdk({...sdk, event_category: value});
      if (targetName && eventName) {
        setExitCounter(true);
      } else setExitCounter(false);
    }
  }
  function validateSQLString(input: string, extend: boolean = false): boolean {
    let sqlPattern = /[,*^|&]+/;
    let sqlKeywords = ["\\"]
    if(extend) {
      sqlPattern = /["'`;%!?{}*^|&/()#,]+/;
      sqlKeywords = ['[', ']',"\\"];
      // sqlKeywords = ['[', ']', "\\", 'SELECT', 'INSERT', 'UPDATE', 'DELETE', 'DROP', 'CREATE', 'ALTER', 'TRUNCATE', 'TABLE', 'DATABASE', 'WHERE', ' AND ', ' OR '];
    }
    if(sqlPattern.test(input)) return false;
    for (const keyword of sqlKeywords) {
      if (input.toUpperCase().includes(keyword)) {
        return false;
      }
    }
    return true
  }

  const changeEventName = (value:string)=> {
    if(validateSQLString(value, true)) {
      setEventName(value);
      setData( {...data, event_name: value});
      setSdk( {...sdk, event_name: value});
      if(targetName && value) {
        setExitCounter(true);
      } else setExitCounter(false);
    }
  }
  const changeEventType = (value:string)=> {
    if(validateSQLString(value, true)) {
      setEventType(value);
      setData({...data, event_type: value});
      setSdk({...sdk, event_type: value});
      if (targetName && eventName) {
        setExitCounter(true);
      } else setExitCounter(false);
    }
  }

  const onSubmitFirst = () => {
    if (!(targetName)) {
      return;
    }
    setExitCounter(false);
    let temp = JSON.parse(JSON.stringify(data));
    if(calculateModels) {
      temp = {...temp, calculate_attribution_models: calculateModels};
    }
    if(type) {
      temp = {...temp, type};
    }
    if(type === 'purchase') {
      temp = {...temp, event_name: 'purchase'};
    }
    if(type === 'add_to_cart') {
      temp = {...temp, event_name: 'add_to_cart'};
    }
    setData(temp);
    onSubmitSecond(temp);
  }
  const [sdkFuncText, setSdkFuncText] = useState<string>(
    `<script>
_targetadsTag('event', ${JSON.stringify(sdk)})
</script>`);
  const purchaseExampleText = `<script>
_targetadsTag('purchase', {
    'id': '<ID покупки>',       // Уникальный идентификатор покупки(обязательно)
    'quantity': <количество>,   // Общее количество товаров в покупке(опционально)
    'amount': <сумма покупки>,  // Общая сумма покупки (опционально)
    'items': [{     // Массив с информацией о товарах (не может быть пустым)
        'id': '<id товара>',    // Уникальный идентификатор товара (обязательно, если нет title)
        'title': '<название продукта>', // Название товара (обязательно, если нет id)
        'category1': '<категория 1 продукта>', // Категория товара (опционально)
        'category2': '<категория 2 продукта>', // Подкатегория товара (опционально)
        'brand': '<бренд товара>',     // Бренд товара (опционально)
        'variant': '<вариант товара>', // Вариант товара, например размер или цвет (опционально)
        'price': <стоимость товара>    // Стоимость товара (опционально)
    }]
});
</script>`
  const addToCartExampleText = `<script>
_targetadsTag('add_to_cart', {
    'id': '<id товара>',          // Уникальный идентификатор товара (обязательно)
    'title': '<название продукта>', // Название товара (обязательно, если нет ID)
    'category1': '<категория 1 продукта>', // Категория товара (опционально)
    'category2': '<категория 2 продукта>', // Подкатегория товара (опционально)
    'brand': '<бренд товара>',     // Бренд товара (опционально)
    'variant': '<вариант товара>', // Вариант товара, например размер или цвет (опционально)
    'price': <стоимость товара>    // Стоимость товара (опционально)
});
</script>`
  const onSubmitSecond = (data:ICurrentEvents) => {
    if (!(eventName)) {
      return;
    }
    if(edit) {
      dispatch(updateEvent({data: data, projectId: Number(projectId), eventsId: Number(eventsId)})).then((r)=> {
        if(r && r.meta.requestStatus !== 'rejected') {
          setExitCounter(false);
          setStep(2);
          sendSdk('event', {
            'event_type': 'edit',
            'event_category': 'events',
            'event_name': 'active',
            'event_value': '10',
            'event_params': {
              'project_id': projectId,
              'user_id': reduxUserId,
              'event_id': eventsId
            }
          });
        }
      })
    } else {
      dispatch(updateProjectEvents({data: data, projectId: Number(projectId)})).then((r)=>{
        if(r && r.meta.requestStatus !== 'rejected') {
          setExitCounter(false);
          setStep(2);
          sendSdk('event', {
            'event_type': 'create',
            'event_category': 'events',
            'event_name': 'active',
            'event_value': '10',
            'event_params': {
              'project_id': projectId,
              'user_id': reduxUserId,
              'event_id': eventsId
            }
          });
        }
      })
    }
  }

  const onSubmit = (type:string='all') => {
    setExitCounter(false);

    setStep(1);
    setTargetName('');
    setTargetPrice(-1);
    setCalculateModels(false);
    setEventType('');
    setEventCategory('');
    setEventName('');
    setEventValue(-1);
    setData({
      event_type: eventType,
      event_category: eventCategory,
      event_name: eventName
    });
    setSdk({
      event_type: eventType,
      event_category: eventCategory,
      event_name: eventName
    });
    setCheckCopy(false);
    if(type === 'all')
      navigate(`/projects/${projectId}/events/`);
    if(type === 'create')
      navigate(`/projects/${projectId}/events/create/`);
  }

  let progressbar;
  if(step === 1) {
    progressbar = cls.stepFirst;
  }
  if(step === 2) {
    progressbar = cls.stepSecond;
  }
  if(step === 3) {
    progressbar = cls.stepThird;
  }

  const [styleUrl, setStyleUrl] = useState(false)
  const [checkCopy, setCheckCopy] = useState<boolean>(false)
  const copyToClipboard = (text: string = '') => {
    navigator.clipboard.writeText(text).then(r => document.execCommand('Copy')).then(()=>{
      setCheckCopy(true);
      setTimeout(()=>setCheckCopy(false), 3000);
    });
  }

  const [showDocs, setShowDocs] = useState(false);
  const [showCopyBlock, setShowCopyBlock] = useState(!!edit);
  const onHandlerModal = ()=> {
    setShowDocs(true);
  }

  const refDocs = useRef<HTMLDivElement | null>(null)
  useOnClickOutside(() => setShowDocs(false), [refDocs]);
  useEffect(() => {
    if(exitCounter) {
      setShowCopyBlock(false);
    }
  }, [exitCounter]);
  useEffect(() => {
    if(sdk) {
      if(!sdk.event_category) delete sdk.event_category;
      if(!sdk.event_type) delete sdk.event_type;
      if(!sdk.event_value) delete sdk.event_value;
      setSdkFuncText(`<script>
    _targetadsTag('event', ${JSON.stringify(sdk)})
</script>`);
    }
  }, [sdk]);

  useEffect(() => {
    if(data) {
      validateAll(data);
    }
  }, [data]);

  return (
    <div className={cls.eventCreate}>
      <div>
        <div className={cls.progress}>
          <div className={cls.progress__title}>{edit ? trans('Event editing', language) : trans('Event creation', language)}</div>
        </div>
        {step === 1 ?
          <div className={cls.container}>
            <div className={cls.form}>
              <div className={cls.wrapper}>
                <div className={cls.input_section}>
                  <span className={cls.input_label}>{trans('Event target name', language)} * <Tooltip
                      text={trans("The name will be displayed only in the interface. We advise you to give simple names, for example, 'Application for consultation'", language)}/></span>
                  <input className={cls.input} type='text' value={targetName}
                         onChange={(e) => changeTargetName(e.target.value)}/>
                  {errors['event_target_name'] && <span className={cls.error}>{errors['event_target_name']}</span>}
                </div>
                <div className={cls.input_section}>
                  <span className={cls.input_label}>{trans('Target cost', language)} <Tooltip
                      text={trans('Add the target cost of your event (KPI) and in some reports we will show you ineffective campaigns.', language)}/></span>
                  <input className={styleUrl ? cls.input_error : cls.input}
                         type='text'
                         value={targetPrice !== -1 ? targetPrice : ''}
                         onChange={(e: ChangeEvent<HTMLInputElement>) => {
                           changeTargetPrice(e.target.value);
                         }}
                  />
                </div>

                {type !== 'purchase' && type !== 'add_to_cart' && <div className={cls.input_section}>
                  <span className={cls.input_label}>{trans('Event type', language)}</span>
                  <input
                      className={cls.input}
                      type='text'
                      value={eventType}
                      onChange={(e) => changeEventType(e.target.value)}
                      placeholder={trans('Input event type', language)}/>
                </div>}
                {type !== 'purchase' && type !== 'add_to_cart' && <div className={cls.input_section}>
                  <span>{trans('Event category', language)}</span>
                  <input
                      className={styleUrl ? cls.input_error : cls.input}
                      type='text'
                      value={eventCategory}
                      onChange={(e) => changeEventCategory(e.target.value)}
                      placeholder={trans('Input event category', language)}/>
                </div>}
                {type !== 'purchase' && type !== 'add_to_cart' && <div className={cls.input_section}>
                  <span>{trans('Event name', language)} *</span>
                  <input
                      className={styleUrl ? cls.input_error : cls.input}
                      type='text'
                      value={eventName}
                      onChange={(e) => changeEventName(e.target.value)}
                      placeholder={trans('Input event name', language)}/>
                </div>}
                {type !== 'purchase' && type !== 'add_to_cart' &&<div className={cls.input_section}>
                  <span>{trans('Event value', language)}</span>
                  <input
                      className={styleUrl ? cls.input_error : cls.input}
                      type='text'
                      value={eventValue !== -1 ? eventValue : ''}
                      onChange={(e) => changeEventValue(e.target.value)}
                      placeholder={trans('Input event value', language)}/>
                </div>}
              </div>
              <div className={cls.entity}>
                <div className={cls.info_wrapper}>
                  <label htmlFor='switcher'>{trans('Calculate attribution models', language)} <Tooltip
                      text={trans('Whether to calculate attribution models for this event.', language)}/></label>
                  <Toggle
                      className={cls.switch}
                      name={'status'}
                      isSlider={true}
                      isChecked={calculateModels}
                      onChange={changeCalculateModels}/>
                </div>
              </div>
              <div className={cls.btns}>
                <Btn
                  className={(exitCounter) ? cls.submit_btn : cls.submit_btn_disactive}
                  onClick={onSubmitFirst}
                  text={edit ? trans('Update event', language) : trans('Create event', language)}
                  disabled={!!(errors['general'] || errors['event_target_name'])}
                />
                {errors['general'] && <span className={cls.error}>{errors['general']}</span>}
              </div>
            </div>
            {showCopyBlock && <div className={cls.section_copy_block}>
              { type == 'web_event' &&
                <>
                  <Code code={sdkFuncText} sizeText={'s'} isCopyBtn={true} copyBtnColor={'border-blue'}/>
                  <p className={cls.text}>{trans('Add the pixel code to the HTML code in all the places where the event is triggered when interacting with.', language)}</p>
                </>
              }
              {type === 'purchase' &&
                  <div>
                    <p className={cls.paragraph}>Для отслеживания событий, связанных с покупкой, вам нужно вызвать функцию с типом события &quot;purchase&quot; при завершении транзакции или заказа.</p>
                    <p className={cls.paragraph}>Пример кода для вызова события &quot;purchase&quot;:</p>
                    <p className={cls.paragraph}><strong>Важно!</strong> не просто копировать код, а подставлять нужные значения</p>
                    <Code
                        className={cls.mb}
                        code={purchaseExampleText}
                        sizeText={'s'}
                        isCopyBtn={true}
                        copyBtnColor={'border-blue'}
                    />
                    <Btn
                        text={'Инструкция'}
                        widthAuto={false}
                        color='light-blue'
                        onClick={()=> {
                          window.open('https://targetads.io/help/event_eccomerce', '_blank');
                        }}
                    />
                  </div>
              }
              {type === 'add_to_cart' &&
                  <div>
                    <p className={cls.paragraph}>
                      Для того чтобы отслеживать добавление товара в корзину, вы должны вызвать специальное событие,
                      когда пользователь нажимает на кнопку &quot;Добавить в корзину&quot;. Код, вызывающий это событие,
                      обычно размещают в функции, обрабатывающей добавление товара в корзину.
                    </p>
                    <p className={cls.paragraph}>Пример кода для вызова события &quot;add_to_cart&quot;:</p>
                    <p className={cls.paragraph}><strong>Важно!</strong> не просто копировать код, а подставлять нужные
                      значения</p>
                    <Code className={cls.mb} code={addToCartExampleText} sizeText={'s'} isCopyBtn={true}
                          copyBtnColor={'border-blue'}/>
                    <Btn
                        text={'Инструкция'}
                        widthAuto={false}
                        color='light-blue'
                        onClick={() => {
                          window.open('https://targetads.io/help/event_eccomerce', '_blank');
                        }}
                    />
                  </div>
              }
              {type === 'offline_event' &&
                  <div>
                    <p className={cls.paragraph}>Если желаете отправить отдельное событие, следует использовать метод событийной загрузки. Для этого отправьте POST-запрос на следующий URL:</p>
                    <Code
                        code={`https://eye.targetads.io/api/project/event/upload?pid=${projectId}`}
                        sizeText={'s'}
                    />
                    <p className={cls.paragraph}>В теле запроса необходимо передать данные в формате JSON с информацией о событии.</p>

                    <p className={cls.paragraph}><br/>Для пакетной загрузки оффлайн событий следует отправить POST-запрос на следующий URL:</p>
                      <Code
                          code={`https://eye.targetads.io/api/project/event/upload/batch?pid=${projectId}`}
                          sizeText={'s'}
                      />
                    <p className={cls.paragraph}>Тело запроса должно содержать JSON, в котором указывается массив объектов, соответствующих формату, используемому для событийной загрузки.</p>
                    <Btn
                        text={'Инструкция'}
                        widthAuto={false}
                        color='light-blue'
                        onClick={()=> {
                          window.open('https://targetads.io/help/event_offline', '_blank');
                        }}
                    />
                  </div>
              }
              {type === 'offline_call' &&
                  <div>
                    <p className={cls.paragraph}>Из системы CallTouch в систему TargetAds можно импортировать целевые события, по которым будет рассчитана атрибуция, включая кроссплатформенную postview атрибуцию с глубиной до 90 дней.</p>
                    <p className={cls.paragraph}>В настоящее время метчинг данных осуществляется по идентификаторам Google Analytics или Яндекс Метрики. Чтобы интеграция заработала, необходимо передавать эти идентификаторы в CallTouch, а на вашем сайте должен быть установлен JavaScript-код системы TargetAds, который автоматически собирает данные.</p>
                    <p className={cls.paragraph}><strong>Важно!</strong> Интеграция находится на стадии тестирования, и для активации расчета атрибуции на вашем проекте требуется обратиться к вашему менеджеру.</p>
                    <Btn
                        text={'Инструкция'}
                        widthAuto={false}
                        color='light-blue'
                        onClick={()=> {
                          window.open('https://targetads.io/help/event_calltouch', '_blank');
                        }}
                    />
                  </div>
              }
              {type === 'in_app' &&
                  <div>
                    <p className={cls.paragraph}>Инструкции по интеграции событий из мобильного приложения в нашу систему:</p>
                    <Btn
                        className={cls.mb}
                        text={'AppsFlyer'}
                        widthAuto={false}
                        color='light-blue'
                        onClick={()=> {
                          window.open('https://targetads.io/help/event_appsflyer', '_blank');
                        }}
                    />
                    <Btn
                        className={cls.mb}
                        text={'AppMetrika'}
                        widthAuto={false}
                        color='light-blue'
                        onClick={()=> {
                          window.open('https://targetads.io/help/event_appmetrika', '_blank');
                        }}
                    />
                    <Btn
                        text={'Adjust'}
                        widthAuto={false}
                        color='light-blue'
                        onClick={()=> {
                          window.open('https://targetads.io/help/event_adjust', '_blank');
                        }}
                    />
                  </div>
              }

              {(type === 'web_event' || type === 'purchase' || type === 'add_to_cart') && <div className={cls.btns}>
                <Btn
                    text={trans('Counter documentation', language)}
                    color='light-blue'
                    onClick={onHandlerModal}
                />
              </div>}
            </div>}
          </div>
            :
            ''
        }
        {step === 2 ?
            <div className={cls.form_finish}>
              { type == 'web_event' &&
                <>
                  <Code code={sdkFuncText} sizeText={'s'} isCopyBtn={true} copyBtnColor={'border-blue'}/>
                  <p className={cls.text}>{trans('Add the pixel code to the HTML code in all the places where the event is triggered when interacting with.', language)}</p>
                </>
              }
              {type === 'purchase' &&
                  <div>
                    <p className={cls.paragraph}>Для отслеживания событий, связанных с покупкой, вам нужно вызвать функцию с типом события &quot;purchase&quot; при завершении транзакции или заказа.</p>
                    <p className={cls.paragraph}>Пример кода для вызова события &quot;purchase&quot;:</p>
                    <p className={cls.paragraph}><strong>Важно!</strong> не просто копировать код, а подставлять нужные значения</p>
                    <Code
                        className={cls.mb}
                        code={purchaseExampleText}
                        sizeText={'s'}
                        isCopyBtn={true}
                        copyBtnColor={'border-blue'}
                    />
                    <Btn
                        text={'Инструкция'}
                        widthAuto={false}
                        color='light-blue'
                        onClick={()=> {
                          window.open('https://targetads.io/help/event_eccomerce', '_blank');
                        }}
                    />
                  </div>
              }
              {type === 'add_to_cart' &&
                  <div>
                    <p className={cls.paragraph}>
                      Для того чтобы отслеживать добавление товара в корзину, вы должны вызвать специальное событие,
                      когда пользователь нажимает на кнопку &quot;Добавить в корзину&quot;. Код, вызывающий это событие,
                      обычно размещают в функции, обрабатывающей добавление товара в корзину.
                    </p>
                    <p className={cls.paragraph}>Пример кода для вызова события &quot;add_to_cart&quot;:</p>
                    <p className={cls.paragraph}><strong>Важно!</strong> не просто копировать код, а подставлять нужные
                      значения</p>
                    <Code className={cls.mb} code={addToCartExampleText} sizeText={'s'} isCopyBtn={true}
                          copyBtnColor={'border-blue'}/>
                    <Btn
                        text={'Инструкция'}
                        widthAuto={false}
                        color='light-blue'
                        onClick={() => {
                          window.open('https://targetads.io/help/event_eccomerce', '_blank');
                        }}
                    />
                  </div>
              }
              {type === 'offline_event' &&
                  <div>
                    <p className={cls.paragraph}>Если желаете отправить отдельное событие, следует использовать метод событийной загрузки. Для этого отправьте POST-запрос на следующий URL:</p>
                    <Code
                        code={`https://eye.targetads.io/api/project/event/upload?pid=${projectId}`}
                        sizeText={'s'}
                    />
                    <p className={cls.paragraph}>В теле запроса необходимо передать данные в формате JSON с информацией о событии.</p>

                    <p className={cls.paragraph}><br/>Для пакетной загрузки оффлайн событий следует отправить POST-запрос на следующий URL:</p>
                      <Code
                          code={`https://eye.targetads.io/api/project/event/upload/batch?pid=${projectId}`}
                          sizeText={'s'}
                      />
                    <p className={cls.paragraph}>Тело запроса должно содержать JSON, в котором указывается массив объектов, соответствующих формату, используемому для событийной загрузки.</p>
                    <Btn
                        text={'Инструкция'}
                        widthAuto={false}
                        color='light-blue'
                        onClick={()=> {
                          window.open('https://targetads.io/help/event_offline', '_blank');
                        }}
                    />
                  </div>
              }
              {type === 'offline_call' &&
                  <div>
                    <p className={cls.paragraph}>Из системы CallTouch в систему TargetAds можно импортировать целевые события, по которым будет рассчитана атрибуция, включая кроссплатформенную postview атрибуцию с глубиной до 90 дней.</p>
                    <p className={cls.paragraph}>В настоящее время метчинг данных осуществляется по идентификаторам Google Analytics или Яндекс Метрики. Чтобы интеграция заработала, необходимо передавать эти идентификаторы в CallTouch, а на вашем сайте должен быть установлен JavaScript-код системы TargetAds, который автоматически собирает данные.</p>
                    <p className={cls.paragraph}><strong>Важно!</strong> Интеграция находится на стадии тестирования, и для активации расчета атрибуции на вашем проекте требуется обратиться к вашему менеджеру.</p>
                    <Btn
                        text={'Инструкция'}
                        widthAuto={false}
                        color='light-blue'
                        onClick={()=> {
                          window.open('https://targetads.io/help/event_calltouch', '_blank');
                        }}
                    />
                  </div>
              }
              {type === 'in_app' &&
                  <div>
                    <p className={cls.paragraph}>Инструкции по интеграции событий из мобильного приложения в нашу систему:</p>
                    <Btn
                        className={cls.mb}
                        text={'AppsFlyer'}
                        widthAuto={false}
                        color='light-blue'
                        onClick={()=> {
                          window.open('https://targetads.io/help/event_appsflyer', '_blank');
                        }}
                    />
                    <Btn
                        className={cls.mb}
                        text={'AppMetrika'}
                        widthAuto={false}
                        color='light-blue'
                        onClick={()=> {
                          window.open('https://targetads.io/help/event_appmetrika', '_blank');
                        }}
                    />
                    <Btn
                        text={'Adjust'}
                        widthAuto={false}
                        color='light-blue'
                        onClick={()=> {
                          window.open('https://targetads.io/help/event_adjust', '_blank');
                        }}
                    />
                  </div>
              }
              <div className={cls.btns}>
                <button className={cls.submit_btn} onClick={() => onSubmit()}>
                  {trans('All events', language)}
                </button>
                {(type === 'web_event' || type === 'purchase' || type === 'add_to_cart') &&<Btn
                    text={trans('Counter documentation', language)}
                    color='light-blue'
                    onClick={onHandlerModal}
                />}
              </div>
            </div>
            :
            ''
        }
      </div>
      {showDocs && <div className={cls.modal} ref={refDocs}>
      <DocsCounter title={trans('Counter documentation', language)} onClose={()=>setShowDocs(false)}/>
      </div>}
    </div>
  );
};

export default EventsCreate;