import './style.scss';
import {FC, FocusEvent, FormEvent, useCallback, useEffect, useState} from "react";
import {DefaultObject, IField, ISelectOption} from "../../../interfaces/common.d";
import {Btn, Select} from "../../../ui";
import {trans} from "../../../_locales";
import {updateSources, validateAll, validateOne, validateUrlValue} from "../../../utils";
import {newPlatformForm, updateCampaignForm} from "../../../data";
import {useAppSelector} from "../../../redux/hooks";
import {currentUserLang} from "../../../redux/slices/user";
import {newSource as newSourceSelector, sources} from "../../../redux/slices/sources";
import Input from "../../../ui/Input";
import {IAccordion} from "../../ModalForm/ModalForm.d";


interface IEditMultipleActions {
    projectId:number,
    action: 'campaigns' | 'placements',
    cb:(data: JSON) => any,
    ids: Array<number>,
    initialValues?: DefaultObject<string>,
    resetBtn?:boolean,
    resetText?:string,
    onReset?:any,
    geoList?:any,
    targetingList?:any,
}


const EditMultipleActions:FC<IEditMultipleActions> = ({
    projectId,
    action,
    cb,
    ids,
    initialValues={},
    resetBtn=false,
    resetText='Cancel',
    onReset=()=>null,
    geoList={},
    targetingList={}
}) => {
    const language = useAppSelector(currentUserLang);
    const reduxSources = useAppSelector(sources);
    const newSource = useAppSelector(newSourceSelector);

    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

    const [form, setForm] = useState<DefaultObject<string>>(initialValues);
    const [errors, setErrors] = useState<DefaultObject<boolean>>({});
    const handleFieldChange = useCallback((data: {[index: string]: string }) => {
        const name = Object.keys(data)[0];
        const currentForm = {...form, ...data};
        setForm(currentForm)
        if (name === 'onboarding_url' && currentForm[name]) {
            const {errorsUrl} = validateUrlValue(
                [{
                    tag: 'input',
                    type: 'text',
                    name: 'onboarding_url',
                    placeholder: '',
                    label: trans('Onboarding url', language),
                    errorLabel: trans('Enter the correct url', language),
                    isUrl: true,
                    isRequired: true
                }] as (IField | IAccordion)[],
                currentForm
            );
            setErrors({...errors, ...errorsUrl})
        } else setErrors({})
    }, [form, errors]);

    const handleFormSubmit = useCallback((evt: FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
        if (errors['onboarding_url']) return;

        let currentData = {};
        Object.entries(form).forEach(([name, value]) =>
            currentData = value ?
                Object.assign({}, currentData, {[name]: value}) :
                currentData
        );

        cb(currentData as JSON);
        return;
    }, [form, errors, cb]);

    let sourceFields:any = []
    if(action === 'campaigns') {
        sourceFields = updateSources(
            [{
                tag: 'select',
                type: 'text',
                name: 'source_id',
                placeholder: '',
                label: trans('Source', language),
                tooltip: trans('The source is an advertising system, which is convenient for grouping reports and looking at intersections between each other. The source can also add default macros to pixels.', language),
                // tooltip: trans(`Your advertising source, for example, Yandex, myTarget or Hybrid`, language),
                errorLabel: trans('Select the source', language),
                isRequired: true,
                isOpenUp: false,
                hasAddButton: true,
                options: [],
            }],
            reduxSources,
            newSource
        );
    }
    if(action === 'placements') {
        // console.log(projectId, action, ids);
        // - Посадочная страница
        // - Формат
        // - Гео
        // - Таргетинги
        // - Размер креатива
    }

    return(
        <div className='edit-multi-actions'>
            <div className={`edit-multi-actions__field`}>
                <Select
                    name={'status'}
                    value={form['status']}
                    label={trans('Status', language)}
                    tooltip={trans('Regardless of the status change, statistics will continue to be collected', language)}
                    // isInvalid={errors[field.name]}
                    required={false}
                    isOpenUp={false}
                    errorLabel={trans('Select the status', language)}
                    // hasAddButton={true}
                    activeOption={''}
                    options={[
                        {
                            label: 'Активный',
                            value: 'active'
                        },
                        {
                            label: 'Неактивный',
                            value: 'inactive'
                        },
                          {
                            label: 'Архивный',
                            value: 'archive'
                          }
                    ] as ISelectOption[]}
                    onChange={handleFieldChange}
                />
                {!form['status'] &&
                    <p className='edit-multi-actions__text'>{trans('If the value is left empty, then multiple changes will not be applied to this field', language)}</p>}
            </div>
            {action === 'campaigns' && <div>
                <div className={`edit-multi-actions__field`}>
                    <Select
                        name={'source_id'}
                        value={form['source_id']}
                        label={trans('Source', language)}
                        tooltip={trans('The source is an advertising system, which is convenient for grouping reports and looking at intersections between each other. The source can also add default macros to pixels.', language)}
                        // isInvalid={errors[field.name]}
                        required={false}
                        isOpenUp={false}
                        errorLabel={trans('Select the source', language)}
                        hasAddButton={true}
                        activeOption={sourceFields[0] ? sourceFields[0].activeOption : ''}
                        options={(sourceFields[0] ? sourceFields[0].options : []) as ISelectOption[]}
                        onChange={handleFieldChange}
                    />
                    {!form['source_id'] &&
                        <p className='edit-multi-actions__text'>{trans('If the value is left empty, then multiple changes will not be applied to this field', language)}</p>}
                </div>
                <div className={`edit-multi-actions__field`}>
                    <Select
                        name={'purchase_type'}
                        value={form['purchase_type']}
                        label={trans('Purchase type', language)}
                        tooltip={trans('Purchase tooltip', language)}
                        // isInvalid={errors[field.name]}
                        required={false}
                        isOpenUp={false}
                        errorLabel={trans('Select the type', language)}
                        // hasAddButton={true}
                        activeOption={''}
                        options={[
                            {
                                label: 'CPM',
                                value: 'CPM'
                            },
                            {
                                label: 'CPC',
                                value: 'CPC'
                            },
                            {
                                label: 'CPV',
                                value: 'CPV'
                            },
                            {
                                label: 'FIX',
                                value: 'FIX'
                            },
                        ] as ISelectOption[]}
                        onChange={handleFieldChange}
                    />
                    {!form['purchase_type'] &&
                        <p className='edit-multi-actions__text'>{trans('If the value is left empty, then multiple changes will not be applied to this field', language)}</p>}
                </div>
            </div>}
            <div className={`edit-multi-actions__field`}>
                <Input
                    type={'text'}
                    name={'onboarding_url'}
                    value={form['onboarding_url']}
                    placeholder={''}
                    label={trans('Onboarding url', language)}
                    tooltip={trans('This page will be redirected after clicking on the clickable pixel', language)}
                    errorLabel={trans('Enter the correct url', language)}
                    required={false}
                    isInvalid={errors['onboarding_url']}
                    onChange={handleFieldChange}
                    onBlur={() => null}
                />
                {!form['onboarding_url'] &&
                    <p className='edit-multi-actions__text'>{trans('If the value is left empty, then multiple changes will not be applied to this field', language)}</p>}
            </div>
            {action === 'placements' && <div>
                <div className={`edit-multi-actions__field`}>
                    <Select
                        name={'format'}
                        value={form['format']}
                        label={trans('Format', language)}
                        tooltip={''}
                        // isInvalid={errors[field.name]}
                        required={false}
                        isOpenUp={false}
                        errorLabel={trans('Select the format', language)}
                        hasAddButton={false}
                        activeOption={''}
                        options={[
                            {
                                label: trans('JPEG', language),
                                value: 'JPEG',
                            },
                            {
                                label: trans('GIF', language),
                                value: 'GIF',
                            },
                            {
                                label: trans('HTML', language),
                                value: 'HTML',
                            },
                            {
                                label: trans('Video', language),
                                value: 'Video',
                            },
                            {
                                label: trans('Other', language),
                                value: 'Other',
                            }
                        ] as ISelectOption[]}
                        onChange={handleFieldChange}
                    />
                    {!form['format'] &&
                        <p className='edit-multi-actions__text'>{trans('If the value is left empty, then multiple changes will not be applied to this field', language)}</p>}
                </div>
                <div className={`edit-multi-actions__field ${!form['geo'] ? 'edit-multi-actions__different' : ''}`}>
                    <Input
                        type={'text'}
                        name={'geo'}
                        value={form['geo']}
                        placeholder={''}
                        label={trans('Geo', language)}
                        tooltip={''}
                        errorLabel={''}
                        required={false}
                        isInvalid={false}
                        onChange={handleFieldChange}
                        onBlur={() => null}
                        tip={geoList}
                    />
                    {!form['geo'] &&
                        <p className='edit-multi-actions__text'>{trans('If the value is left empty, then multiple changes will not be applied to this field', language)}</p>}
                </div>
                <div className={`edit-multi-actions__field`}>
                    <Input
                        type={'text'}
                        name={'targeting'}
                        value={form['targeting']}
                        placeholder={''}
                        label={trans('Targeting', language)}
                        tooltip={''}
                        errorLabel={''}
                        required={false}
                        isInvalid={false}
                        onChange={handleFieldChange}
                        onBlur={() => null}
                        tip={targetingList}
                    />
                    {!form['targeting'] &&
                        <p className='edit-multi-actions__text'>{trans('If the value is left empty, then multiple changes will not be applied to this field', language)}</p>}
                </div>
                <div className={`edit-multi-actions__field`}>
                    <Input
                        type={'text'}
                        name={'size'}
                        value={form['size']}
                        placeholder={''}
                        label={trans('Creative size', language)}
                        tooltip={''}
                        errorLabel={''}
                        required={false}
                        isInvalid={false}
                        onChange={handleFieldChange}
                        onBlur={() => null}
                        tip={{
                            '240x400px': '240 x 400 px',
                            '300x250px': '300 x 250 px',
                            '300x500px': '300 x 500 px',
                            '300x600px': '300 x 600 px',
                            '336x280px': '336 x 280 px',
                            '640x100px': '640 x 100 px',
                            '640x200px': '640 x 200 px',
                            '640x960px': '640 x 960 px',
                            '728x90px': '728 x 90 px',
                            '960x640px': '960 x 640 px',
                            '970x250px': '970 x 250 px',
                            '160x600px': '160 x 600 px',
                            '1000x120px': '1000 x 120 px',
                            '200x200px': '200 x 200 px',
                            '250x250px': '250 x 250 px',
                            '250x360px': '250 x 360 px',
                            '580x400px': '580 x 400 px',
                            '930x180px': '930 x 180 px',
                            '970x90px': '970 x 90 px',
                            '980x120px': '980 x 120 px',
                            '300x1050px': '300 x 1050 px',
                            '300x50px': '300 x 50 px',
                            '1200x628px': '1200 x 628 px',
                            '600x600px': '600 x 600 px',
                            '628x1120px': '628 х 1120 px',
                            '1920x1080px': '1920 x 1080 px',
                            '1080х1920рх': '1080 х 1920 рх',
                            '1280х720': '1280 х 720',
                            '504х283': '504 х 283',
                            '1080x1080px': '1080  x 1080 рх',
                            '700x500': '700 x 500',
                            '1200x630': '1200 x 630',
                        }}
                    />
                    {!form['size'] &&
                        <p className='edit-multi-actions__text'>{trans('If the value is left empty, then multiple changes will not be applied to this field', language)}</p>}
                </div>
            </div>}
            <div className='edit-multi-actions__btns'>
                {
                    resetBtn &&
                    <Btn
                        type='reset'
                        text={trans(resetText, language)}
                        size='lg'
                        widthAuto={false}
                        onClick={onReset}
                    />
                }
                <Btn
                    type='submit'
                    text={trans('Apply', language)}
                    color='blue'
                    widthAuto={false}
                    size='lg'
                    disabled={isSubmitDisabled}
                    onClick={handleFormSubmit}
                />
            </div>
        </div>
    )
}

export default EditMultipleActions;